import { defineStore } from 'pinia'

export const useCountryStore = defineStore('country', {
  state: () => ({
    selectedContinentName : '',
    selectedCountryCode : '',
    selectedIsoCode : ''
  }),
  actions: {
    setContinentName: (continentName) => {
      this.selectedContinentName = continentName
    },
    setCountry: (country) => {
      this.selectedCountryCode = country
    },
    setIsoCode: (isoCode) => {
      this.selectedIsoCode = isoCode
    },
  },
})