import { default as check_45healthvPvq9x7xbEMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/check-health.vue?macro=true";
import { default as game0tjGKLAlpSMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/game.vue?macro=true";
import { default as index_tmpcHNziUbepkMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index_tmp.vue?macro=true";
import { default as indexauUri8xjLYMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index.vue?macro=true";
import { default as owners_45worldox0oWm8rIfMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/owners-world.vue?macro=true";
import { default as ep01_020101_01crEM2AzLwgMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020101_01.vue?macro=true";
import { default as ep01_020102_01fPe3YZXmoHMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020102_01.vue?macro=true";
import { default as indexR5Vf0GTMauMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/index.vue?macro=true";
import { default as ep02_020201_01MZ0cK3yu7RMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/ep02_020201_01.vue?macro=true";
import { default as indexudoLWl0DsBMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/index.vue?macro=true";
import { default as ep03_020301_01CnC4eAz4ivMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_01.vue?macro=true";
import { default as ep03_020301_02vpRoD8pL7eMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_02.vue?macro=true";
import { default as ep03_020301_03QJWjWCWOCbMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_03.vue?macro=true";
import { default as indexsZSzhLyqHOMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/index.vue?macro=true";
import { default as ep04_020402_01dP1WzuXA9lMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_01.vue?macro=true";
import { default as ep04_020402_02Djes6G77DaMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_02.vue?macro=true";
import { default as ep04_020402_03Mf4C9VOA4PMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_03.vue?macro=true";
import { default as indexMshPijsKsHMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/index.vue?macro=true";
import { default as ep05_020500_01zHd45NmpqTMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_01.vue?macro=true";
import { default as ep05_020500_02fPQhyERLxrMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_02.vue?macro=true";
import { default as ep05_020500_03MzoDWlpnaUMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_03.vue?macro=true";
import { default as indexATVge2BJofMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/index.vue?macro=true";
import { default as ep06_020601_0142MAAqLu3aMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020601_01.vue?macro=true";
import { default as ep06_020602_01wSDb0YJi6TMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020602_01.vue?macro=true";
import { default as ep06_020603_01ZMqX1oSzaCMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020603_01.vue?macro=true";
import { default as indexAw5C94z6GDMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/index.vue?macro=true";
import { default as ep07_020702_01gMzkm2ABgCMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_01.vue?macro=true";
import { default as ep07_020702_02daoTPNWWYkMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_02.vue?macro=true";
import { default as ep07_020702_03TVkj5r5qUOMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_03.vue?macro=true";
import { default as indexdBlOHZvZxbMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/index.vue?macro=true";
import { default as ep08_02080131BxmzsPqiMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020801.vue?macro=true";
import { default as ep08_020802zM4eLdG1ngMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020802.vue?macro=true";
import { default as ep08_020803SNvsZisusBMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020803.vue?macro=true";
import { default as indexRqAU4C7ynrMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/index.vue?macro=true";
import { default as indexZ9qoxqqjixMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/index.vue?macro=true";
import { default as indexol3jX3QVz9Meta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/intro/index.vue?macro=true";
import { default as index1LYDvqicdAMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/outro/index.vue?macro=true";
import { default as indexjtPc4c3QOSMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/01/index.vue?macro=true";
import { default as index96kEVgf3JqMeta } from "/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/index.vue?macro=true";
export default [
  {
    name: check_45healthvPvq9x7xbEMeta?.name ?? "check-health___en-US",
    path: check_45healthvPvq9x7xbEMeta?.path ?? "/check-health",
    meta: check_45healthvPvq9x7xbEMeta || {},
    alias: check_45healthvPvq9x7xbEMeta?.alias || [],
    redirect: check_45healthvPvq9x7xbEMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/check-health.vue").then(m => m.default || m)
  },
  {
    name: check_45healthvPvq9x7xbEMeta?.name ?? "check-health___de",
    path: check_45healthvPvq9x7xbEMeta?.path ?? "/de/check-health",
    meta: check_45healthvPvq9x7xbEMeta || {},
    alias: check_45healthvPvq9x7xbEMeta?.alias || [],
    redirect: check_45healthvPvq9x7xbEMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/check-health.vue").then(m => m.default || m)
  },
  {
    name: check_45healthvPvq9x7xbEMeta?.name ?? "check-health___fr",
    path: check_45healthvPvq9x7xbEMeta?.path ?? "/fr/check-health",
    meta: check_45healthvPvq9x7xbEMeta || {},
    alias: check_45healthvPvq9x7xbEMeta?.alias || [],
    redirect: check_45healthvPvq9x7xbEMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/check-health.vue").then(m => m.default || m)
  },
  {
    name: check_45healthvPvq9x7xbEMeta?.name ?? "check-health___no",
    path: check_45healthvPvq9x7xbEMeta?.path ?? "/no/check-health",
    meta: check_45healthvPvq9x7xbEMeta || {},
    alias: check_45healthvPvq9x7xbEMeta?.alias || [],
    redirect: check_45healthvPvq9x7xbEMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/check-health.vue").then(m => m.default || m)
  },
  {
    name: check_45healthvPvq9x7xbEMeta?.name ?? "check-health___nl",
    path: check_45healthvPvq9x7xbEMeta?.path ?? "/nl/check-health",
    meta: check_45healthvPvq9x7xbEMeta || {},
    alias: check_45healthvPvq9x7xbEMeta?.alias || [],
    redirect: check_45healthvPvq9x7xbEMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/check-health.vue").then(m => m.default || m)
  },
  {
    name: check_45healthvPvq9x7xbEMeta?.name ?? "check-health___es",
    path: check_45healthvPvq9x7xbEMeta?.path ?? "/es/check-health",
    meta: check_45healthvPvq9x7xbEMeta || {},
    alias: check_45healthvPvq9x7xbEMeta?.alias || [],
    redirect: check_45healthvPvq9x7xbEMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/check-health.vue").then(m => m.default || m)
  },
  {
    name: game0tjGKLAlpSMeta?.name ?? "game___en-US",
    path: game0tjGKLAlpSMeta?.path ?? "/game",
    meta: game0tjGKLAlpSMeta || {},
    alias: game0tjGKLAlpSMeta?.alias || [],
    redirect: game0tjGKLAlpSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/game.vue").then(m => m.default || m)
  },
  {
    name: game0tjGKLAlpSMeta?.name ?? "game___de",
    path: game0tjGKLAlpSMeta?.path ?? "/de/game",
    meta: game0tjGKLAlpSMeta || {},
    alias: game0tjGKLAlpSMeta?.alias || [],
    redirect: game0tjGKLAlpSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/game.vue").then(m => m.default || m)
  },
  {
    name: game0tjGKLAlpSMeta?.name ?? "game___fr",
    path: game0tjGKLAlpSMeta?.path ?? "/fr/game",
    meta: game0tjGKLAlpSMeta || {},
    alias: game0tjGKLAlpSMeta?.alias || [],
    redirect: game0tjGKLAlpSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/game.vue").then(m => m.default || m)
  },
  {
    name: game0tjGKLAlpSMeta?.name ?? "game___no",
    path: game0tjGKLAlpSMeta?.path ?? "/no/game",
    meta: game0tjGKLAlpSMeta || {},
    alias: game0tjGKLAlpSMeta?.alias || [],
    redirect: game0tjGKLAlpSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/game.vue").then(m => m.default || m)
  },
  {
    name: game0tjGKLAlpSMeta?.name ?? "game___nl",
    path: game0tjGKLAlpSMeta?.path ?? "/nl/game",
    meta: game0tjGKLAlpSMeta || {},
    alias: game0tjGKLAlpSMeta?.alias || [],
    redirect: game0tjGKLAlpSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/game.vue").then(m => m.default || m)
  },
  {
    name: game0tjGKLAlpSMeta?.name ?? "game___es",
    path: game0tjGKLAlpSMeta?.path ?? "/es/game",
    meta: game0tjGKLAlpSMeta || {},
    alias: game0tjGKLAlpSMeta?.alias || [],
    redirect: game0tjGKLAlpSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/game.vue").then(m => m.default || m)
  },
  {
    name: index_tmpcHNziUbepkMeta?.name ?? "index_tmp___en-US",
    path: index_tmpcHNziUbepkMeta?.path ?? "/index_tmp",
    meta: index_tmpcHNziUbepkMeta || {},
    alias: index_tmpcHNziUbepkMeta?.alias || [],
    redirect: index_tmpcHNziUbepkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index_tmp.vue").then(m => m.default || m)
  },
  {
    name: index_tmpcHNziUbepkMeta?.name ?? "index_tmp___de",
    path: index_tmpcHNziUbepkMeta?.path ?? "/de/index_tmp",
    meta: index_tmpcHNziUbepkMeta || {},
    alias: index_tmpcHNziUbepkMeta?.alias || [],
    redirect: index_tmpcHNziUbepkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index_tmp.vue").then(m => m.default || m)
  },
  {
    name: index_tmpcHNziUbepkMeta?.name ?? "index_tmp___fr",
    path: index_tmpcHNziUbepkMeta?.path ?? "/fr/index_tmp",
    meta: index_tmpcHNziUbepkMeta || {},
    alias: index_tmpcHNziUbepkMeta?.alias || [],
    redirect: index_tmpcHNziUbepkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index_tmp.vue").then(m => m.default || m)
  },
  {
    name: index_tmpcHNziUbepkMeta?.name ?? "index_tmp___no",
    path: index_tmpcHNziUbepkMeta?.path ?? "/no/index_tmp",
    meta: index_tmpcHNziUbepkMeta || {},
    alias: index_tmpcHNziUbepkMeta?.alias || [],
    redirect: index_tmpcHNziUbepkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index_tmp.vue").then(m => m.default || m)
  },
  {
    name: index_tmpcHNziUbepkMeta?.name ?? "index_tmp___nl",
    path: index_tmpcHNziUbepkMeta?.path ?? "/nl/index_tmp",
    meta: index_tmpcHNziUbepkMeta || {},
    alias: index_tmpcHNziUbepkMeta?.alias || [],
    redirect: index_tmpcHNziUbepkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index_tmp.vue").then(m => m.default || m)
  },
  {
    name: index_tmpcHNziUbepkMeta?.name ?? "index_tmp___es",
    path: index_tmpcHNziUbepkMeta?.path ?? "/es/index_tmp",
    meta: index_tmpcHNziUbepkMeta || {},
    alias: index_tmpcHNziUbepkMeta?.alias || [],
    redirect: index_tmpcHNziUbepkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index_tmp.vue").then(m => m.default || m)
  },
  {
    name: indexauUri8xjLYMeta?.name ?? "index___en-US",
    path: indexauUri8xjLYMeta?.path ?? "/",
    meta: indexauUri8xjLYMeta || {},
    alias: indexauUri8xjLYMeta?.alias || [],
    redirect: indexauUri8xjLYMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexauUri8xjLYMeta?.name ?? "index___de",
    path: indexauUri8xjLYMeta?.path ?? "/de",
    meta: indexauUri8xjLYMeta || {},
    alias: indexauUri8xjLYMeta?.alias || [],
    redirect: indexauUri8xjLYMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexauUri8xjLYMeta?.name ?? "index___fr",
    path: indexauUri8xjLYMeta?.path ?? "/fr",
    meta: indexauUri8xjLYMeta || {},
    alias: indexauUri8xjLYMeta?.alias || [],
    redirect: indexauUri8xjLYMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexauUri8xjLYMeta?.name ?? "index___no",
    path: indexauUri8xjLYMeta?.path ?? "/no",
    meta: indexauUri8xjLYMeta || {},
    alias: indexauUri8xjLYMeta?.alias || [],
    redirect: indexauUri8xjLYMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexauUri8xjLYMeta?.name ?? "index___nl",
    path: indexauUri8xjLYMeta?.path ?? "/nl",
    meta: indexauUri8xjLYMeta || {},
    alias: indexauUri8xjLYMeta?.alias || [],
    redirect: indexauUri8xjLYMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexauUri8xjLYMeta?.name ?? "index___es",
    path: indexauUri8xjLYMeta?.path ?? "/es",
    meta: indexauUri8xjLYMeta || {},
    alias: indexauUri8xjLYMeta?.alias || [],
    redirect: indexauUri8xjLYMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: owners_45worldox0oWm8rIfMeta?.name ?? "owners-world___en-US",
    path: owners_45worldox0oWm8rIfMeta?.path ?? "/owners-world",
    meta: owners_45worldox0oWm8rIfMeta || {},
    alias: owners_45worldox0oWm8rIfMeta?.alias || [],
    redirect: owners_45worldox0oWm8rIfMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/owners-world.vue").then(m => m.default || m)
  },
  {
    name: owners_45worldox0oWm8rIfMeta?.name ?? "owners-world___de",
    path: owners_45worldox0oWm8rIfMeta?.path ?? "/de/owners-world",
    meta: owners_45worldox0oWm8rIfMeta || {},
    alias: owners_45worldox0oWm8rIfMeta?.alias || [],
    redirect: owners_45worldox0oWm8rIfMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/owners-world.vue").then(m => m.default || m)
  },
  {
    name: owners_45worldox0oWm8rIfMeta?.name ?? "owners-world___fr",
    path: owners_45worldox0oWm8rIfMeta?.path ?? "/fr/owners-world",
    meta: owners_45worldox0oWm8rIfMeta || {},
    alias: owners_45worldox0oWm8rIfMeta?.alias || [],
    redirect: owners_45worldox0oWm8rIfMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/owners-world.vue").then(m => m.default || m)
  },
  {
    name: owners_45worldox0oWm8rIfMeta?.name ?? "owners-world___no",
    path: owners_45worldox0oWm8rIfMeta?.path ?? "/no/owners-world",
    meta: owners_45worldox0oWm8rIfMeta || {},
    alias: owners_45worldox0oWm8rIfMeta?.alias || [],
    redirect: owners_45worldox0oWm8rIfMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/owners-world.vue").then(m => m.default || m)
  },
  {
    name: owners_45worldox0oWm8rIfMeta?.name ?? "owners-world___nl",
    path: owners_45worldox0oWm8rIfMeta?.path ?? "/nl/owners-world",
    meta: owners_45worldox0oWm8rIfMeta || {},
    alias: owners_45worldox0oWm8rIfMeta?.alias || [],
    redirect: owners_45worldox0oWm8rIfMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/owners-world.vue").then(m => m.default || m)
  },
  {
    name: owners_45worldox0oWm8rIfMeta?.name ?? "owners-world___es",
    path: owners_45worldox0oWm8rIfMeta?.path ?? "/es/owners-world",
    meta: owners_45worldox0oWm8rIfMeta || {},
    alias: owners_45worldox0oWm8rIfMeta?.alias || [],
    redirect: owners_45worldox0oWm8rIfMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/owners-world.vue").then(m => m.default || m)
  },
  {
    name: ep01_020101_01crEM2AzLwgMeta?.name ?? "story-ep01-ep01_020101_01___en-US",
    path: ep01_020101_01crEM2AzLwgMeta?.path ?? "/story/ep01/ep01_020101_01",
    meta: ep01_020101_01crEM2AzLwgMeta || {},
    alias: ep01_020101_01crEM2AzLwgMeta?.alias || [],
    redirect: ep01_020101_01crEM2AzLwgMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020101_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020101_01crEM2AzLwgMeta?.name ?? "story-ep01-ep01_020101_01___de",
    path: ep01_020101_01crEM2AzLwgMeta?.path ?? "/de/story/ep01/ep01_020101_01",
    meta: ep01_020101_01crEM2AzLwgMeta || {},
    alias: ep01_020101_01crEM2AzLwgMeta?.alias || [],
    redirect: ep01_020101_01crEM2AzLwgMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020101_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020101_01crEM2AzLwgMeta?.name ?? "story-ep01-ep01_020101_01___fr",
    path: ep01_020101_01crEM2AzLwgMeta?.path ?? "/fr/story/ep01/ep01_020101_01",
    meta: ep01_020101_01crEM2AzLwgMeta || {},
    alias: ep01_020101_01crEM2AzLwgMeta?.alias || [],
    redirect: ep01_020101_01crEM2AzLwgMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020101_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020101_01crEM2AzLwgMeta?.name ?? "story-ep01-ep01_020101_01___no",
    path: ep01_020101_01crEM2AzLwgMeta?.path ?? "/no/story/ep01/ep01_020101_01",
    meta: ep01_020101_01crEM2AzLwgMeta || {},
    alias: ep01_020101_01crEM2AzLwgMeta?.alias || [],
    redirect: ep01_020101_01crEM2AzLwgMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020101_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020101_01crEM2AzLwgMeta?.name ?? "story-ep01-ep01_020101_01___nl",
    path: ep01_020101_01crEM2AzLwgMeta?.path ?? "/nl/story/ep01/ep01_020101_01",
    meta: ep01_020101_01crEM2AzLwgMeta || {},
    alias: ep01_020101_01crEM2AzLwgMeta?.alias || [],
    redirect: ep01_020101_01crEM2AzLwgMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020101_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020101_01crEM2AzLwgMeta?.name ?? "story-ep01-ep01_020101_01___es",
    path: ep01_020101_01crEM2AzLwgMeta?.path ?? "/es/story/ep01/ep01_020101_01",
    meta: ep01_020101_01crEM2AzLwgMeta || {},
    alias: ep01_020101_01crEM2AzLwgMeta?.alias || [],
    redirect: ep01_020101_01crEM2AzLwgMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020101_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020102_01fPe3YZXmoHMeta?.name ?? "story-ep01-ep01_020102_01___en-US",
    path: ep01_020102_01fPe3YZXmoHMeta?.path ?? "/story/ep01/ep01_020102_01",
    meta: ep01_020102_01fPe3YZXmoHMeta || {},
    alias: ep01_020102_01fPe3YZXmoHMeta?.alias || [],
    redirect: ep01_020102_01fPe3YZXmoHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020102_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020102_01fPe3YZXmoHMeta?.name ?? "story-ep01-ep01_020102_01___de",
    path: ep01_020102_01fPe3YZXmoHMeta?.path ?? "/de/story/ep01/ep01_020102_01",
    meta: ep01_020102_01fPe3YZXmoHMeta || {},
    alias: ep01_020102_01fPe3YZXmoHMeta?.alias || [],
    redirect: ep01_020102_01fPe3YZXmoHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020102_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020102_01fPe3YZXmoHMeta?.name ?? "story-ep01-ep01_020102_01___fr",
    path: ep01_020102_01fPe3YZXmoHMeta?.path ?? "/fr/story/ep01/ep01_020102_01",
    meta: ep01_020102_01fPe3YZXmoHMeta || {},
    alias: ep01_020102_01fPe3YZXmoHMeta?.alias || [],
    redirect: ep01_020102_01fPe3YZXmoHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020102_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020102_01fPe3YZXmoHMeta?.name ?? "story-ep01-ep01_020102_01___no",
    path: ep01_020102_01fPe3YZXmoHMeta?.path ?? "/no/story/ep01/ep01_020102_01",
    meta: ep01_020102_01fPe3YZXmoHMeta || {},
    alias: ep01_020102_01fPe3YZXmoHMeta?.alias || [],
    redirect: ep01_020102_01fPe3YZXmoHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020102_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020102_01fPe3YZXmoHMeta?.name ?? "story-ep01-ep01_020102_01___nl",
    path: ep01_020102_01fPe3YZXmoHMeta?.path ?? "/nl/story/ep01/ep01_020102_01",
    meta: ep01_020102_01fPe3YZXmoHMeta || {},
    alias: ep01_020102_01fPe3YZXmoHMeta?.alias || [],
    redirect: ep01_020102_01fPe3YZXmoHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020102_01.vue").then(m => m.default || m)
  },
  {
    name: ep01_020102_01fPe3YZXmoHMeta?.name ?? "story-ep01-ep01_020102_01___es",
    path: ep01_020102_01fPe3YZXmoHMeta?.path ?? "/es/story/ep01/ep01_020102_01",
    meta: ep01_020102_01fPe3YZXmoHMeta || {},
    alias: ep01_020102_01fPe3YZXmoHMeta?.alias || [],
    redirect: ep01_020102_01fPe3YZXmoHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/ep01_020102_01.vue").then(m => m.default || m)
  },
  {
    name: indexR5Vf0GTMauMeta?.name ?? "story-ep01___en-US",
    path: indexR5Vf0GTMauMeta?.path ?? "/story/ep01",
    meta: indexR5Vf0GTMauMeta || {},
    alias: indexR5Vf0GTMauMeta?.alias || [],
    redirect: indexR5Vf0GTMauMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/index.vue").then(m => m.default || m)
  },
  {
    name: indexR5Vf0GTMauMeta?.name ?? "story-ep01___de",
    path: indexR5Vf0GTMauMeta?.path ?? "/de/story/ep01",
    meta: indexR5Vf0GTMauMeta || {},
    alias: indexR5Vf0GTMauMeta?.alias || [],
    redirect: indexR5Vf0GTMauMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/index.vue").then(m => m.default || m)
  },
  {
    name: indexR5Vf0GTMauMeta?.name ?? "story-ep01___fr",
    path: indexR5Vf0GTMauMeta?.path ?? "/fr/story/ep01",
    meta: indexR5Vf0GTMauMeta || {},
    alias: indexR5Vf0GTMauMeta?.alias || [],
    redirect: indexR5Vf0GTMauMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/index.vue").then(m => m.default || m)
  },
  {
    name: indexR5Vf0GTMauMeta?.name ?? "story-ep01___no",
    path: indexR5Vf0GTMauMeta?.path ?? "/no/story/ep01",
    meta: indexR5Vf0GTMauMeta || {},
    alias: indexR5Vf0GTMauMeta?.alias || [],
    redirect: indexR5Vf0GTMauMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/index.vue").then(m => m.default || m)
  },
  {
    name: indexR5Vf0GTMauMeta?.name ?? "story-ep01___nl",
    path: indexR5Vf0GTMauMeta?.path ?? "/nl/story/ep01",
    meta: indexR5Vf0GTMauMeta || {},
    alias: indexR5Vf0GTMauMeta?.alias || [],
    redirect: indexR5Vf0GTMauMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/index.vue").then(m => m.default || m)
  },
  {
    name: indexR5Vf0GTMauMeta?.name ?? "story-ep01___es",
    path: indexR5Vf0GTMauMeta?.path ?? "/es/story/ep01",
    meta: indexR5Vf0GTMauMeta || {},
    alias: indexR5Vf0GTMauMeta?.alias || [],
    redirect: indexR5Vf0GTMauMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep01/index.vue").then(m => m.default || m)
  },
  {
    name: ep02_020201_01MZ0cK3yu7RMeta?.name ?? "story-ep02-ep02_020201_01___en-US",
    path: ep02_020201_01MZ0cK3yu7RMeta?.path ?? "/story/ep02/ep02_020201_01",
    meta: ep02_020201_01MZ0cK3yu7RMeta || {},
    alias: ep02_020201_01MZ0cK3yu7RMeta?.alias || [],
    redirect: ep02_020201_01MZ0cK3yu7RMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/ep02_020201_01.vue").then(m => m.default || m)
  },
  {
    name: ep02_020201_01MZ0cK3yu7RMeta?.name ?? "story-ep02-ep02_020201_01___de",
    path: ep02_020201_01MZ0cK3yu7RMeta?.path ?? "/de/story/ep02/ep02_020201_01",
    meta: ep02_020201_01MZ0cK3yu7RMeta || {},
    alias: ep02_020201_01MZ0cK3yu7RMeta?.alias || [],
    redirect: ep02_020201_01MZ0cK3yu7RMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/ep02_020201_01.vue").then(m => m.default || m)
  },
  {
    name: ep02_020201_01MZ0cK3yu7RMeta?.name ?? "story-ep02-ep02_020201_01___fr",
    path: ep02_020201_01MZ0cK3yu7RMeta?.path ?? "/fr/story/ep02/ep02_020201_01",
    meta: ep02_020201_01MZ0cK3yu7RMeta || {},
    alias: ep02_020201_01MZ0cK3yu7RMeta?.alias || [],
    redirect: ep02_020201_01MZ0cK3yu7RMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/ep02_020201_01.vue").then(m => m.default || m)
  },
  {
    name: ep02_020201_01MZ0cK3yu7RMeta?.name ?? "story-ep02-ep02_020201_01___no",
    path: ep02_020201_01MZ0cK3yu7RMeta?.path ?? "/no/story/ep02/ep02_020201_01",
    meta: ep02_020201_01MZ0cK3yu7RMeta || {},
    alias: ep02_020201_01MZ0cK3yu7RMeta?.alias || [],
    redirect: ep02_020201_01MZ0cK3yu7RMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/ep02_020201_01.vue").then(m => m.default || m)
  },
  {
    name: ep02_020201_01MZ0cK3yu7RMeta?.name ?? "story-ep02-ep02_020201_01___nl",
    path: ep02_020201_01MZ0cK3yu7RMeta?.path ?? "/nl/story/ep02/ep02_020201_01",
    meta: ep02_020201_01MZ0cK3yu7RMeta || {},
    alias: ep02_020201_01MZ0cK3yu7RMeta?.alias || [],
    redirect: ep02_020201_01MZ0cK3yu7RMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/ep02_020201_01.vue").then(m => m.default || m)
  },
  {
    name: ep02_020201_01MZ0cK3yu7RMeta?.name ?? "story-ep02-ep02_020201_01___es",
    path: ep02_020201_01MZ0cK3yu7RMeta?.path ?? "/es/story/ep02/ep02_020201_01",
    meta: ep02_020201_01MZ0cK3yu7RMeta || {},
    alias: ep02_020201_01MZ0cK3yu7RMeta?.alias || [],
    redirect: ep02_020201_01MZ0cK3yu7RMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/ep02_020201_01.vue").then(m => m.default || m)
  },
  {
    name: indexudoLWl0DsBMeta?.name ?? "story-ep02___en-US",
    path: indexudoLWl0DsBMeta?.path ?? "/story/ep02",
    meta: indexudoLWl0DsBMeta || {},
    alias: indexudoLWl0DsBMeta?.alias || [],
    redirect: indexudoLWl0DsBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/index.vue").then(m => m.default || m)
  },
  {
    name: indexudoLWl0DsBMeta?.name ?? "story-ep02___de",
    path: indexudoLWl0DsBMeta?.path ?? "/de/story/ep02",
    meta: indexudoLWl0DsBMeta || {},
    alias: indexudoLWl0DsBMeta?.alias || [],
    redirect: indexudoLWl0DsBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/index.vue").then(m => m.default || m)
  },
  {
    name: indexudoLWl0DsBMeta?.name ?? "story-ep02___fr",
    path: indexudoLWl0DsBMeta?.path ?? "/fr/story/ep02",
    meta: indexudoLWl0DsBMeta || {},
    alias: indexudoLWl0DsBMeta?.alias || [],
    redirect: indexudoLWl0DsBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/index.vue").then(m => m.default || m)
  },
  {
    name: indexudoLWl0DsBMeta?.name ?? "story-ep02___no",
    path: indexudoLWl0DsBMeta?.path ?? "/no/story/ep02",
    meta: indexudoLWl0DsBMeta || {},
    alias: indexudoLWl0DsBMeta?.alias || [],
    redirect: indexudoLWl0DsBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/index.vue").then(m => m.default || m)
  },
  {
    name: indexudoLWl0DsBMeta?.name ?? "story-ep02___nl",
    path: indexudoLWl0DsBMeta?.path ?? "/nl/story/ep02",
    meta: indexudoLWl0DsBMeta || {},
    alias: indexudoLWl0DsBMeta?.alias || [],
    redirect: indexudoLWl0DsBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/index.vue").then(m => m.default || m)
  },
  {
    name: indexudoLWl0DsBMeta?.name ?? "story-ep02___es",
    path: indexudoLWl0DsBMeta?.path ?? "/es/story/ep02",
    meta: indexudoLWl0DsBMeta || {},
    alias: indexudoLWl0DsBMeta?.alias || [],
    redirect: indexudoLWl0DsBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep02/index.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_01CnC4eAz4ivMeta?.name ?? "story-ep03-ep03_020301_01___en-US",
    path: ep03_020301_01CnC4eAz4ivMeta?.path ?? "/story/ep03/ep03_020301_01",
    meta: ep03_020301_01CnC4eAz4ivMeta || {},
    alias: ep03_020301_01CnC4eAz4ivMeta?.alias || [],
    redirect: ep03_020301_01CnC4eAz4ivMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_01.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_01CnC4eAz4ivMeta?.name ?? "story-ep03-ep03_020301_01___de",
    path: ep03_020301_01CnC4eAz4ivMeta?.path ?? "/de/story/ep03/ep03_020301_01",
    meta: ep03_020301_01CnC4eAz4ivMeta || {},
    alias: ep03_020301_01CnC4eAz4ivMeta?.alias || [],
    redirect: ep03_020301_01CnC4eAz4ivMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_01.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_01CnC4eAz4ivMeta?.name ?? "story-ep03-ep03_020301_01___fr",
    path: ep03_020301_01CnC4eAz4ivMeta?.path ?? "/fr/story/ep03/ep03_020301_01",
    meta: ep03_020301_01CnC4eAz4ivMeta || {},
    alias: ep03_020301_01CnC4eAz4ivMeta?.alias || [],
    redirect: ep03_020301_01CnC4eAz4ivMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_01.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_01CnC4eAz4ivMeta?.name ?? "story-ep03-ep03_020301_01___no",
    path: ep03_020301_01CnC4eAz4ivMeta?.path ?? "/no/story/ep03/ep03_020301_01",
    meta: ep03_020301_01CnC4eAz4ivMeta || {},
    alias: ep03_020301_01CnC4eAz4ivMeta?.alias || [],
    redirect: ep03_020301_01CnC4eAz4ivMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_01.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_01CnC4eAz4ivMeta?.name ?? "story-ep03-ep03_020301_01___nl",
    path: ep03_020301_01CnC4eAz4ivMeta?.path ?? "/nl/story/ep03/ep03_020301_01",
    meta: ep03_020301_01CnC4eAz4ivMeta || {},
    alias: ep03_020301_01CnC4eAz4ivMeta?.alias || [],
    redirect: ep03_020301_01CnC4eAz4ivMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_01.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_01CnC4eAz4ivMeta?.name ?? "story-ep03-ep03_020301_01___es",
    path: ep03_020301_01CnC4eAz4ivMeta?.path ?? "/es/story/ep03/ep03_020301_01",
    meta: ep03_020301_01CnC4eAz4ivMeta || {},
    alias: ep03_020301_01CnC4eAz4ivMeta?.alias || [],
    redirect: ep03_020301_01CnC4eAz4ivMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_01.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_02vpRoD8pL7eMeta?.name ?? "story-ep03-ep03_020301_02___en-US",
    path: ep03_020301_02vpRoD8pL7eMeta?.path ?? "/story/ep03/ep03_020301_02",
    meta: ep03_020301_02vpRoD8pL7eMeta || {},
    alias: ep03_020301_02vpRoD8pL7eMeta?.alias || [],
    redirect: ep03_020301_02vpRoD8pL7eMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_02.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_02vpRoD8pL7eMeta?.name ?? "story-ep03-ep03_020301_02___de",
    path: ep03_020301_02vpRoD8pL7eMeta?.path ?? "/de/story/ep03/ep03_020301_02",
    meta: ep03_020301_02vpRoD8pL7eMeta || {},
    alias: ep03_020301_02vpRoD8pL7eMeta?.alias || [],
    redirect: ep03_020301_02vpRoD8pL7eMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_02.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_02vpRoD8pL7eMeta?.name ?? "story-ep03-ep03_020301_02___fr",
    path: ep03_020301_02vpRoD8pL7eMeta?.path ?? "/fr/story/ep03/ep03_020301_02",
    meta: ep03_020301_02vpRoD8pL7eMeta || {},
    alias: ep03_020301_02vpRoD8pL7eMeta?.alias || [],
    redirect: ep03_020301_02vpRoD8pL7eMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_02.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_02vpRoD8pL7eMeta?.name ?? "story-ep03-ep03_020301_02___no",
    path: ep03_020301_02vpRoD8pL7eMeta?.path ?? "/no/story/ep03/ep03_020301_02",
    meta: ep03_020301_02vpRoD8pL7eMeta || {},
    alias: ep03_020301_02vpRoD8pL7eMeta?.alias || [],
    redirect: ep03_020301_02vpRoD8pL7eMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_02.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_02vpRoD8pL7eMeta?.name ?? "story-ep03-ep03_020301_02___nl",
    path: ep03_020301_02vpRoD8pL7eMeta?.path ?? "/nl/story/ep03/ep03_020301_02",
    meta: ep03_020301_02vpRoD8pL7eMeta || {},
    alias: ep03_020301_02vpRoD8pL7eMeta?.alias || [],
    redirect: ep03_020301_02vpRoD8pL7eMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_02.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_02vpRoD8pL7eMeta?.name ?? "story-ep03-ep03_020301_02___es",
    path: ep03_020301_02vpRoD8pL7eMeta?.path ?? "/es/story/ep03/ep03_020301_02",
    meta: ep03_020301_02vpRoD8pL7eMeta || {},
    alias: ep03_020301_02vpRoD8pL7eMeta?.alias || [],
    redirect: ep03_020301_02vpRoD8pL7eMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_02.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_03QJWjWCWOCbMeta?.name ?? "story-ep03-ep03_020301_03___en-US",
    path: ep03_020301_03QJWjWCWOCbMeta?.path ?? "/story/ep03/ep03_020301_03",
    meta: ep03_020301_03QJWjWCWOCbMeta || {},
    alias: ep03_020301_03QJWjWCWOCbMeta?.alias || [],
    redirect: ep03_020301_03QJWjWCWOCbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_03.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_03QJWjWCWOCbMeta?.name ?? "story-ep03-ep03_020301_03___de",
    path: ep03_020301_03QJWjWCWOCbMeta?.path ?? "/de/story/ep03/ep03_020301_03",
    meta: ep03_020301_03QJWjWCWOCbMeta || {},
    alias: ep03_020301_03QJWjWCWOCbMeta?.alias || [],
    redirect: ep03_020301_03QJWjWCWOCbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_03.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_03QJWjWCWOCbMeta?.name ?? "story-ep03-ep03_020301_03___fr",
    path: ep03_020301_03QJWjWCWOCbMeta?.path ?? "/fr/story/ep03/ep03_020301_03",
    meta: ep03_020301_03QJWjWCWOCbMeta || {},
    alias: ep03_020301_03QJWjWCWOCbMeta?.alias || [],
    redirect: ep03_020301_03QJWjWCWOCbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_03.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_03QJWjWCWOCbMeta?.name ?? "story-ep03-ep03_020301_03___no",
    path: ep03_020301_03QJWjWCWOCbMeta?.path ?? "/no/story/ep03/ep03_020301_03",
    meta: ep03_020301_03QJWjWCWOCbMeta || {},
    alias: ep03_020301_03QJWjWCWOCbMeta?.alias || [],
    redirect: ep03_020301_03QJWjWCWOCbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_03.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_03QJWjWCWOCbMeta?.name ?? "story-ep03-ep03_020301_03___nl",
    path: ep03_020301_03QJWjWCWOCbMeta?.path ?? "/nl/story/ep03/ep03_020301_03",
    meta: ep03_020301_03QJWjWCWOCbMeta || {},
    alias: ep03_020301_03QJWjWCWOCbMeta?.alias || [],
    redirect: ep03_020301_03QJWjWCWOCbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_03.vue").then(m => m.default || m)
  },
  {
    name: ep03_020301_03QJWjWCWOCbMeta?.name ?? "story-ep03-ep03_020301_03___es",
    path: ep03_020301_03QJWjWCWOCbMeta?.path ?? "/es/story/ep03/ep03_020301_03",
    meta: ep03_020301_03QJWjWCWOCbMeta || {},
    alias: ep03_020301_03QJWjWCWOCbMeta?.alias || [],
    redirect: ep03_020301_03QJWjWCWOCbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/ep03_020301_03.vue").then(m => m.default || m)
  },
  {
    name: indexsZSzhLyqHOMeta?.name ?? "story-ep03___en-US",
    path: indexsZSzhLyqHOMeta?.path ?? "/story/ep03",
    meta: indexsZSzhLyqHOMeta || {},
    alias: indexsZSzhLyqHOMeta?.alias || [],
    redirect: indexsZSzhLyqHOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/index.vue").then(m => m.default || m)
  },
  {
    name: indexsZSzhLyqHOMeta?.name ?? "story-ep03___de",
    path: indexsZSzhLyqHOMeta?.path ?? "/de/story/ep03",
    meta: indexsZSzhLyqHOMeta || {},
    alias: indexsZSzhLyqHOMeta?.alias || [],
    redirect: indexsZSzhLyqHOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/index.vue").then(m => m.default || m)
  },
  {
    name: indexsZSzhLyqHOMeta?.name ?? "story-ep03___fr",
    path: indexsZSzhLyqHOMeta?.path ?? "/fr/story/ep03",
    meta: indexsZSzhLyqHOMeta || {},
    alias: indexsZSzhLyqHOMeta?.alias || [],
    redirect: indexsZSzhLyqHOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/index.vue").then(m => m.default || m)
  },
  {
    name: indexsZSzhLyqHOMeta?.name ?? "story-ep03___no",
    path: indexsZSzhLyqHOMeta?.path ?? "/no/story/ep03",
    meta: indexsZSzhLyqHOMeta || {},
    alias: indexsZSzhLyqHOMeta?.alias || [],
    redirect: indexsZSzhLyqHOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/index.vue").then(m => m.default || m)
  },
  {
    name: indexsZSzhLyqHOMeta?.name ?? "story-ep03___nl",
    path: indexsZSzhLyqHOMeta?.path ?? "/nl/story/ep03",
    meta: indexsZSzhLyqHOMeta || {},
    alias: indexsZSzhLyqHOMeta?.alias || [],
    redirect: indexsZSzhLyqHOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/index.vue").then(m => m.default || m)
  },
  {
    name: indexsZSzhLyqHOMeta?.name ?? "story-ep03___es",
    path: indexsZSzhLyqHOMeta?.path ?? "/es/story/ep03",
    meta: indexsZSzhLyqHOMeta || {},
    alias: indexsZSzhLyqHOMeta?.alias || [],
    redirect: indexsZSzhLyqHOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep03/index.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_01dP1WzuXA9lMeta?.name ?? "story-ep04-ep04_020402_01___en-US",
    path: ep04_020402_01dP1WzuXA9lMeta?.path ?? "/story/ep04/ep04_020402_01",
    meta: ep04_020402_01dP1WzuXA9lMeta || {},
    alias: ep04_020402_01dP1WzuXA9lMeta?.alias || [],
    redirect: ep04_020402_01dP1WzuXA9lMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_01.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_01dP1WzuXA9lMeta?.name ?? "story-ep04-ep04_020402_01___de",
    path: ep04_020402_01dP1WzuXA9lMeta?.path ?? "/de/story/ep04/ep04_020402_01",
    meta: ep04_020402_01dP1WzuXA9lMeta || {},
    alias: ep04_020402_01dP1WzuXA9lMeta?.alias || [],
    redirect: ep04_020402_01dP1WzuXA9lMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_01.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_01dP1WzuXA9lMeta?.name ?? "story-ep04-ep04_020402_01___fr",
    path: ep04_020402_01dP1WzuXA9lMeta?.path ?? "/fr/story/ep04/ep04_020402_01",
    meta: ep04_020402_01dP1WzuXA9lMeta || {},
    alias: ep04_020402_01dP1WzuXA9lMeta?.alias || [],
    redirect: ep04_020402_01dP1WzuXA9lMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_01.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_01dP1WzuXA9lMeta?.name ?? "story-ep04-ep04_020402_01___no",
    path: ep04_020402_01dP1WzuXA9lMeta?.path ?? "/no/story/ep04/ep04_020402_01",
    meta: ep04_020402_01dP1WzuXA9lMeta || {},
    alias: ep04_020402_01dP1WzuXA9lMeta?.alias || [],
    redirect: ep04_020402_01dP1WzuXA9lMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_01.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_01dP1WzuXA9lMeta?.name ?? "story-ep04-ep04_020402_01___nl",
    path: ep04_020402_01dP1WzuXA9lMeta?.path ?? "/nl/story/ep04/ep04_020402_01",
    meta: ep04_020402_01dP1WzuXA9lMeta || {},
    alias: ep04_020402_01dP1WzuXA9lMeta?.alias || [],
    redirect: ep04_020402_01dP1WzuXA9lMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_01.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_01dP1WzuXA9lMeta?.name ?? "story-ep04-ep04_020402_01___es",
    path: ep04_020402_01dP1WzuXA9lMeta?.path ?? "/es/story/ep04/ep04_020402_01",
    meta: ep04_020402_01dP1WzuXA9lMeta || {},
    alias: ep04_020402_01dP1WzuXA9lMeta?.alias || [],
    redirect: ep04_020402_01dP1WzuXA9lMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_01.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_02Djes6G77DaMeta?.name ?? "story-ep04-ep04_020402_02___en-US",
    path: ep04_020402_02Djes6G77DaMeta?.path ?? "/story/ep04/ep04_020402_02",
    meta: ep04_020402_02Djes6G77DaMeta || {},
    alias: ep04_020402_02Djes6G77DaMeta?.alias || [],
    redirect: ep04_020402_02Djes6G77DaMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_02.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_02Djes6G77DaMeta?.name ?? "story-ep04-ep04_020402_02___de",
    path: ep04_020402_02Djes6G77DaMeta?.path ?? "/de/story/ep04/ep04_020402_02",
    meta: ep04_020402_02Djes6G77DaMeta || {},
    alias: ep04_020402_02Djes6G77DaMeta?.alias || [],
    redirect: ep04_020402_02Djes6G77DaMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_02.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_02Djes6G77DaMeta?.name ?? "story-ep04-ep04_020402_02___fr",
    path: ep04_020402_02Djes6G77DaMeta?.path ?? "/fr/story/ep04/ep04_020402_02",
    meta: ep04_020402_02Djes6G77DaMeta || {},
    alias: ep04_020402_02Djes6G77DaMeta?.alias || [],
    redirect: ep04_020402_02Djes6G77DaMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_02.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_02Djes6G77DaMeta?.name ?? "story-ep04-ep04_020402_02___no",
    path: ep04_020402_02Djes6G77DaMeta?.path ?? "/no/story/ep04/ep04_020402_02",
    meta: ep04_020402_02Djes6G77DaMeta || {},
    alias: ep04_020402_02Djes6G77DaMeta?.alias || [],
    redirect: ep04_020402_02Djes6G77DaMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_02.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_02Djes6G77DaMeta?.name ?? "story-ep04-ep04_020402_02___nl",
    path: ep04_020402_02Djes6G77DaMeta?.path ?? "/nl/story/ep04/ep04_020402_02",
    meta: ep04_020402_02Djes6G77DaMeta || {},
    alias: ep04_020402_02Djes6G77DaMeta?.alias || [],
    redirect: ep04_020402_02Djes6G77DaMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_02.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_02Djes6G77DaMeta?.name ?? "story-ep04-ep04_020402_02___es",
    path: ep04_020402_02Djes6G77DaMeta?.path ?? "/es/story/ep04/ep04_020402_02",
    meta: ep04_020402_02Djes6G77DaMeta || {},
    alias: ep04_020402_02Djes6G77DaMeta?.alias || [],
    redirect: ep04_020402_02Djes6G77DaMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_02.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_03Mf4C9VOA4PMeta?.name ?? "story-ep04-ep04_020402_03___en-US",
    path: ep04_020402_03Mf4C9VOA4PMeta?.path ?? "/story/ep04/ep04_020402_03",
    meta: ep04_020402_03Mf4C9VOA4PMeta || {},
    alias: ep04_020402_03Mf4C9VOA4PMeta?.alias || [],
    redirect: ep04_020402_03Mf4C9VOA4PMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_03.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_03Mf4C9VOA4PMeta?.name ?? "story-ep04-ep04_020402_03___de",
    path: ep04_020402_03Mf4C9VOA4PMeta?.path ?? "/de/story/ep04/ep04_020402_03",
    meta: ep04_020402_03Mf4C9VOA4PMeta || {},
    alias: ep04_020402_03Mf4C9VOA4PMeta?.alias || [],
    redirect: ep04_020402_03Mf4C9VOA4PMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_03.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_03Mf4C9VOA4PMeta?.name ?? "story-ep04-ep04_020402_03___fr",
    path: ep04_020402_03Mf4C9VOA4PMeta?.path ?? "/fr/story/ep04/ep04_020402_03",
    meta: ep04_020402_03Mf4C9VOA4PMeta || {},
    alias: ep04_020402_03Mf4C9VOA4PMeta?.alias || [],
    redirect: ep04_020402_03Mf4C9VOA4PMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_03.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_03Mf4C9VOA4PMeta?.name ?? "story-ep04-ep04_020402_03___no",
    path: ep04_020402_03Mf4C9VOA4PMeta?.path ?? "/no/story/ep04/ep04_020402_03",
    meta: ep04_020402_03Mf4C9VOA4PMeta || {},
    alias: ep04_020402_03Mf4C9VOA4PMeta?.alias || [],
    redirect: ep04_020402_03Mf4C9VOA4PMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_03.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_03Mf4C9VOA4PMeta?.name ?? "story-ep04-ep04_020402_03___nl",
    path: ep04_020402_03Mf4C9VOA4PMeta?.path ?? "/nl/story/ep04/ep04_020402_03",
    meta: ep04_020402_03Mf4C9VOA4PMeta || {},
    alias: ep04_020402_03Mf4C9VOA4PMeta?.alias || [],
    redirect: ep04_020402_03Mf4C9VOA4PMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_03.vue").then(m => m.default || m)
  },
  {
    name: ep04_020402_03Mf4C9VOA4PMeta?.name ?? "story-ep04-ep04_020402_03___es",
    path: ep04_020402_03Mf4C9VOA4PMeta?.path ?? "/es/story/ep04/ep04_020402_03",
    meta: ep04_020402_03Mf4C9VOA4PMeta || {},
    alias: ep04_020402_03Mf4C9VOA4PMeta?.alias || [],
    redirect: ep04_020402_03Mf4C9VOA4PMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/ep04_020402_03.vue").then(m => m.default || m)
  },
  {
    name: indexMshPijsKsHMeta?.name ?? "story-ep04___en-US",
    path: indexMshPijsKsHMeta?.path ?? "/story/ep04",
    meta: indexMshPijsKsHMeta || {},
    alias: indexMshPijsKsHMeta?.alias || [],
    redirect: indexMshPijsKsHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/index.vue").then(m => m.default || m)
  },
  {
    name: indexMshPijsKsHMeta?.name ?? "story-ep04___de",
    path: indexMshPijsKsHMeta?.path ?? "/de/story/ep04",
    meta: indexMshPijsKsHMeta || {},
    alias: indexMshPijsKsHMeta?.alias || [],
    redirect: indexMshPijsKsHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/index.vue").then(m => m.default || m)
  },
  {
    name: indexMshPijsKsHMeta?.name ?? "story-ep04___fr",
    path: indexMshPijsKsHMeta?.path ?? "/fr/story/ep04",
    meta: indexMshPijsKsHMeta || {},
    alias: indexMshPijsKsHMeta?.alias || [],
    redirect: indexMshPijsKsHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/index.vue").then(m => m.default || m)
  },
  {
    name: indexMshPijsKsHMeta?.name ?? "story-ep04___no",
    path: indexMshPijsKsHMeta?.path ?? "/no/story/ep04",
    meta: indexMshPijsKsHMeta || {},
    alias: indexMshPijsKsHMeta?.alias || [],
    redirect: indexMshPijsKsHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/index.vue").then(m => m.default || m)
  },
  {
    name: indexMshPijsKsHMeta?.name ?? "story-ep04___nl",
    path: indexMshPijsKsHMeta?.path ?? "/nl/story/ep04",
    meta: indexMshPijsKsHMeta || {},
    alias: indexMshPijsKsHMeta?.alias || [],
    redirect: indexMshPijsKsHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/index.vue").then(m => m.default || m)
  },
  {
    name: indexMshPijsKsHMeta?.name ?? "story-ep04___es",
    path: indexMshPijsKsHMeta?.path ?? "/es/story/ep04",
    meta: indexMshPijsKsHMeta || {},
    alias: indexMshPijsKsHMeta?.alias || [],
    redirect: indexMshPijsKsHMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep04/index.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_01zHd45NmpqTMeta?.name ?? "story-ep05-ep05_020500_01___en-US",
    path: ep05_020500_01zHd45NmpqTMeta?.path ?? "/story/ep05/ep05_020500_01",
    meta: ep05_020500_01zHd45NmpqTMeta || {},
    alias: ep05_020500_01zHd45NmpqTMeta?.alias || [],
    redirect: ep05_020500_01zHd45NmpqTMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_01.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_01zHd45NmpqTMeta?.name ?? "story-ep05-ep05_020500_01___de",
    path: ep05_020500_01zHd45NmpqTMeta?.path ?? "/de/story/ep05/ep05_020500_01",
    meta: ep05_020500_01zHd45NmpqTMeta || {},
    alias: ep05_020500_01zHd45NmpqTMeta?.alias || [],
    redirect: ep05_020500_01zHd45NmpqTMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_01.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_01zHd45NmpqTMeta?.name ?? "story-ep05-ep05_020500_01___fr",
    path: ep05_020500_01zHd45NmpqTMeta?.path ?? "/fr/story/ep05/ep05_020500_01",
    meta: ep05_020500_01zHd45NmpqTMeta || {},
    alias: ep05_020500_01zHd45NmpqTMeta?.alias || [],
    redirect: ep05_020500_01zHd45NmpqTMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_01.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_01zHd45NmpqTMeta?.name ?? "story-ep05-ep05_020500_01___no",
    path: ep05_020500_01zHd45NmpqTMeta?.path ?? "/no/story/ep05/ep05_020500_01",
    meta: ep05_020500_01zHd45NmpqTMeta || {},
    alias: ep05_020500_01zHd45NmpqTMeta?.alias || [],
    redirect: ep05_020500_01zHd45NmpqTMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_01.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_01zHd45NmpqTMeta?.name ?? "story-ep05-ep05_020500_01___nl",
    path: ep05_020500_01zHd45NmpqTMeta?.path ?? "/nl/story/ep05/ep05_020500_01",
    meta: ep05_020500_01zHd45NmpqTMeta || {},
    alias: ep05_020500_01zHd45NmpqTMeta?.alias || [],
    redirect: ep05_020500_01zHd45NmpqTMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_01.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_01zHd45NmpqTMeta?.name ?? "story-ep05-ep05_020500_01___es",
    path: ep05_020500_01zHd45NmpqTMeta?.path ?? "/es/story/ep05/ep05_020500_01",
    meta: ep05_020500_01zHd45NmpqTMeta || {},
    alias: ep05_020500_01zHd45NmpqTMeta?.alias || [],
    redirect: ep05_020500_01zHd45NmpqTMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_01.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_02fPQhyERLxrMeta?.name ?? "story-ep05-ep05_020500_02___en-US",
    path: ep05_020500_02fPQhyERLxrMeta?.path ?? "/story/ep05/ep05_020500_02",
    meta: ep05_020500_02fPQhyERLxrMeta || {},
    alias: ep05_020500_02fPQhyERLxrMeta?.alias || [],
    redirect: ep05_020500_02fPQhyERLxrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_02.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_02fPQhyERLxrMeta?.name ?? "story-ep05-ep05_020500_02___de",
    path: ep05_020500_02fPQhyERLxrMeta?.path ?? "/de/story/ep05/ep05_020500_02",
    meta: ep05_020500_02fPQhyERLxrMeta || {},
    alias: ep05_020500_02fPQhyERLxrMeta?.alias || [],
    redirect: ep05_020500_02fPQhyERLxrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_02.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_02fPQhyERLxrMeta?.name ?? "story-ep05-ep05_020500_02___fr",
    path: ep05_020500_02fPQhyERLxrMeta?.path ?? "/fr/story/ep05/ep05_020500_02",
    meta: ep05_020500_02fPQhyERLxrMeta || {},
    alias: ep05_020500_02fPQhyERLxrMeta?.alias || [],
    redirect: ep05_020500_02fPQhyERLxrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_02.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_02fPQhyERLxrMeta?.name ?? "story-ep05-ep05_020500_02___no",
    path: ep05_020500_02fPQhyERLxrMeta?.path ?? "/no/story/ep05/ep05_020500_02",
    meta: ep05_020500_02fPQhyERLxrMeta || {},
    alias: ep05_020500_02fPQhyERLxrMeta?.alias || [],
    redirect: ep05_020500_02fPQhyERLxrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_02.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_02fPQhyERLxrMeta?.name ?? "story-ep05-ep05_020500_02___nl",
    path: ep05_020500_02fPQhyERLxrMeta?.path ?? "/nl/story/ep05/ep05_020500_02",
    meta: ep05_020500_02fPQhyERLxrMeta || {},
    alias: ep05_020500_02fPQhyERLxrMeta?.alias || [],
    redirect: ep05_020500_02fPQhyERLxrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_02.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_02fPQhyERLxrMeta?.name ?? "story-ep05-ep05_020500_02___es",
    path: ep05_020500_02fPQhyERLxrMeta?.path ?? "/es/story/ep05/ep05_020500_02",
    meta: ep05_020500_02fPQhyERLxrMeta || {},
    alias: ep05_020500_02fPQhyERLxrMeta?.alias || [],
    redirect: ep05_020500_02fPQhyERLxrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_02.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_03MzoDWlpnaUMeta?.name ?? "story-ep05-ep05_020500_03___en-US",
    path: ep05_020500_03MzoDWlpnaUMeta?.path ?? "/story/ep05/ep05_020500_03",
    meta: ep05_020500_03MzoDWlpnaUMeta || {},
    alias: ep05_020500_03MzoDWlpnaUMeta?.alias || [],
    redirect: ep05_020500_03MzoDWlpnaUMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_03.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_03MzoDWlpnaUMeta?.name ?? "story-ep05-ep05_020500_03___de",
    path: ep05_020500_03MzoDWlpnaUMeta?.path ?? "/de/story/ep05/ep05_020500_03",
    meta: ep05_020500_03MzoDWlpnaUMeta || {},
    alias: ep05_020500_03MzoDWlpnaUMeta?.alias || [],
    redirect: ep05_020500_03MzoDWlpnaUMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_03.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_03MzoDWlpnaUMeta?.name ?? "story-ep05-ep05_020500_03___fr",
    path: ep05_020500_03MzoDWlpnaUMeta?.path ?? "/fr/story/ep05/ep05_020500_03",
    meta: ep05_020500_03MzoDWlpnaUMeta || {},
    alias: ep05_020500_03MzoDWlpnaUMeta?.alias || [],
    redirect: ep05_020500_03MzoDWlpnaUMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_03.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_03MzoDWlpnaUMeta?.name ?? "story-ep05-ep05_020500_03___no",
    path: ep05_020500_03MzoDWlpnaUMeta?.path ?? "/no/story/ep05/ep05_020500_03",
    meta: ep05_020500_03MzoDWlpnaUMeta || {},
    alias: ep05_020500_03MzoDWlpnaUMeta?.alias || [],
    redirect: ep05_020500_03MzoDWlpnaUMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_03.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_03MzoDWlpnaUMeta?.name ?? "story-ep05-ep05_020500_03___nl",
    path: ep05_020500_03MzoDWlpnaUMeta?.path ?? "/nl/story/ep05/ep05_020500_03",
    meta: ep05_020500_03MzoDWlpnaUMeta || {},
    alias: ep05_020500_03MzoDWlpnaUMeta?.alias || [],
    redirect: ep05_020500_03MzoDWlpnaUMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_03.vue").then(m => m.default || m)
  },
  {
    name: ep05_020500_03MzoDWlpnaUMeta?.name ?? "story-ep05-ep05_020500_03___es",
    path: ep05_020500_03MzoDWlpnaUMeta?.path ?? "/es/story/ep05/ep05_020500_03",
    meta: ep05_020500_03MzoDWlpnaUMeta || {},
    alias: ep05_020500_03MzoDWlpnaUMeta?.alias || [],
    redirect: ep05_020500_03MzoDWlpnaUMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/ep05_020500_03.vue").then(m => m.default || m)
  },
  {
    name: indexATVge2BJofMeta?.name ?? "story-ep05___en-US",
    path: indexATVge2BJofMeta?.path ?? "/story/ep05",
    meta: indexATVge2BJofMeta || {},
    alias: indexATVge2BJofMeta?.alias || [],
    redirect: indexATVge2BJofMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/index.vue").then(m => m.default || m)
  },
  {
    name: indexATVge2BJofMeta?.name ?? "story-ep05___de",
    path: indexATVge2BJofMeta?.path ?? "/de/story/ep05",
    meta: indexATVge2BJofMeta || {},
    alias: indexATVge2BJofMeta?.alias || [],
    redirect: indexATVge2BJofMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/index.vue").then(m => m.default || m)
  },
  {
    name: indexATVge2BJofMeta?.name ?? "story-ep05___fr",
    path: indexATVge2BJofMeta?.path ?? "/fr/story/ep05",
    meta: indexATVge2BJofMeta || {},
    alias: indexATVge2BJofMeta?.alias || [],
    redirect: indexATVge2BJofMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/index.vue").then(m => m.default || m)
  },
  {
    name: indexATVge2BJofMeta?.name ?? "story-ep05___no",
    path: indexATVge2BJofMeta?.path ?? "/no/story/ep05",
    meta: indexATVge2BJofMeta || {},
    alias: indexATVge2BJofMeta?.alias || [],
    redirect: indexATVge2BJofMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/index.vue").then(m => m.default || m)
  },
  {
    name: indexATVge2BJofMeta?.name ?? "story-ep05___nl",
    path: indexATVge2BJofMeta?.path ?? "/nl/story/ep05",
    meta: indexATVge2BJofMeta || {},
    alias: indexATVge2BJofMeta?.alias || [],
    redirect: indexATVge2BJofMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/index.vue").then(m => m.default || m)
  },
  {
    name: indexATVge2BJofMeta?.name ?? "story-ep05___es",
    path: indexATVge2BJofMeta?.path ?? "/es/story/ep05",
    meta: indexATVge2BJofMeta || {},
    alias: indexATVge2BJofMeta?.alias || [],
    redirect: indexATVge2BJofMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep05/index.vue").then(m => m.default || m)
  },
  {
    name: ep06_020601_0142MAAqLu3aMeta?.name ?? "story-ep06-ep06_020601_01___en-US",
    path: ep06_020601_0142MAAqLu3aMeta?.path ?? "/story/ep06/ep06_020601_01",
    meta: ep06_020601_0142MAAqLu3aMeta || {},
    alias: ep06_020601_0142MAAqLu3aMeta?.alias || [],
    redirect: ep06_020601_0142MAAqLu3aMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020601_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020601_0142MAAqLu3aMeta?.name ?? "story-ep06-ep06_020601_01___de",
    path: ep06_020601_0142MAAqLu3aMeta?.path ?? "/de/story/ep06/ep06_020601_01",
    meta: ep06_020601_0142MAAqLu3aMeta || {},
    alias: ep06_020601_0142MAAqLu3aMeta?.alias || [],
    redirect: ep06_020601_0142MAAqLu3aMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020601_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020601_0142MAAqLu3aMeta?.name ?? "story-ep06-ep06_020601_01___fr",
    path: ep06_020601_0142MAAqLu3aMeta?.path ?? "/fr/story/ep06/ep06_020601_01",
    meta: ep06_020601_0142MAAqLu3aMeta || {},
    alias: ep06_020601_0142MAAqLu3aMeta?.alias || [],
    redirect: ep06_020601_0142MAAqLu3aMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020601_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020601_0142MAAqLu3aMeta?.name ?? "story-ep06-ep06_020601_01___no",
    path: ep06_020601_0142MAAqLu3aMeta?.path ?? "/no/story/ep06/ep06_020601_01",
    meta: ep06_020601_0142MAAqLu3aMeta || {},
    alias: ep06_020601_0142MAAqLu3aMeta?.alias || [],
    redirect: ep06_020601_0142MAAqLu3aMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020601_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020601_0142MAAqLu3aMeta?.name ?? "story-ep06-ep06_020601_01___nl",
    path: ep06_020601_0142MAAqLu3aMeta?.path ?? "/nl/story/ep06/ep06_020601_01",
    meta: ep06_020601_0142MAAqLu3aMeta || {},
    alias: ep06_020601_0142MAAqLu3aMeta?.alias || [],
    redirect: ep06_020601_0142MAAqLu3aMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020601_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020601_0142MAAqLu3aMeta?.name ?? "story-ep06-ep06_020601_01___es",
    path: ep06_020601_0142MAAqLu3aMeta?.path ?? "/es/story/ep06/ep06_020601_01",
    meta: ep06_020601_0142MAAqLu3aMeta || {},
    alias: ep06_020601_0142MAAqLu3aMeta?.alias || [],
    redirect: ep06_020601_0142MAAqLu3aMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020601_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020602_01wSDb0YJi6TMeta?.name ?? "story-ep06-ep06_020602_01___en-US",
    path: ep06_020602_01wSDb0YJi6TMeta?.path ?? "/story/ep06/ep06_020602_01",
    meta: ep06_020602_01wSDb0YJi6TMeta || {},
    alias: ep06_020602_01wSDb0YJi6TMeta?.alias || [],
    redirect: ep06_020602_01wSDb0YJi6TMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020602_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020602_01wSDb0YJi6TMeta?.name ?? "story-ep06-ep06_020602_01___de",
    path: ep06_020602_01wSDb0YJi6TMeta?.path ?? "/de/story/ep06/ep06_020602_01",
    meta: ep06_020602_01wSDb0YJi6TMeta || {},
    alias: ep06_020602_01wSDb0YJi6TMeta?.alias || [],
    redirect: ep06_020602_01wSDb0YJi6TMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020602_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020602_01wSDb0YJi6TMeta?.name ?? "story-ep06-ep06_020602_01___fr",
    path: ep06_020602_01wSDb0YJi6TMeta?.path ?? "/fr/story/ep06/ep06_020602_01",
    meta: ep06_020602_01wSDb0YJi6TMeta || {},
    alias: ep06_020602_01wSDb0YJi6TMeta?.alias || [],
    redirect: ep06_020602_01wSDb0YJi6TMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020602_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020602_01wSDb0YJi6TMeta?.name ?? "story-ep06-ep06_020602_01___no",
    path: ep06_020602_01wSDb0YJi6TMeta?.path ?? "/no/story/ep06/ep06_020602_01",
    meta: ep06_020602_01wSDb0YJi6TMeta || {},
    alias: ep06_020602_01wSDb0YJi6TMeta?.alias || [],
    redirect: ep06_020602_01wSDb0YJi6TMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020602_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020602_01wSDb0YJi6TMeta?.name ?? "story-ep06-ep06_020602_01___nl",
    path: ep06_020602_01wSDb0YJi6TMeta?.path ?? "/nl/story/ep06/ep06_020602_01",
    meta: ep06_020602_01wSDb0YJi6TMeta || {},
    alias: ep06_020602_01wSDb0YJi6TMeta?.alias || [],
    redirect: ep06_020602_01wSDb0YJi6TMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020602_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020602_01wSDb0YJi6TMeta?.name ?? "story-ep06-ep06_020602_01___es",
    path: ep06_020602_01wSDb0YJi6TMeta?.path ?? "/es/story/ep06/ep06_020602_01",
    meta: ep06_020602_01wSDb0YJi6TMeta || {},
    alias: ep06_020602_01wSDb0YJi6TMeta?.alias || [],
    redirect: ep06_020602_01wSDb0YJi6TMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020602_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020603_01ZMqX1oSzaCMeta?.name ?? "story-ep06-ep06_020603_01___en-US",
    path: ep06_020603_01ZMqX1oSzaCMeta?.path ?? "/story/ep06/ep06_020603_01",
    meta: ep06_020603_01ZMqX1oSzaCMeta || {},
    alias: ep06_020603_01ZMqX1oSzaCMeta?.alias || [],
    redirect: ep06_020603_01ZMqX1oSzaCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020603_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020603_01ZMqX1oSzaCMeta?.name ?? "story-ep06-ep06_020603_01___de",
    path: ep06_020603_01ZMqX1oSzaCMeta?.path ?? "/de/story/ep06/ep06_020603_01",
    meta: ep06_020603_01ZMqX1oSzaCMeta || {},
    alias: ep06_020603_01ZMqX1oSzaCMeta?.alias || [],
    redirect: ep06_020603_01ZMqX1oSzaCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020603_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020603_01ZMqX1oSzaCMeta?.name ?? "story-ep06-ep06_020603_01___fr",
    path: ep06_020603_01ZMqX1oSzaCMeta?.path ?? "/fr/story/ep06/ep06_020603_01",
    meta: ep06_020603_01ZMqX1oSzaCMeta || {},
    alias: ep06_020603_01ZMqX1oSzaCMeta?.alias || [],
    redirect: ep06_020603_01ZMqX1oSzaCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020603_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020603_01ZMqX1oSzaCMeta?.name ?? "story-ep06-ep06_020603_01___no",
    path: ep06_020603_01ZMqX1oSzaCMeta?.path ?? "/no/story/ep06/ep06_020603_01",
    meta: ep06_020603_01ZMqX1oSzaCMeta || {},
    alias: ep06_020603_01ZMqX1oSzaCMeta?.alias || [],
    redirect: ep06_020603_01ZMqX1oSzaCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020603_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020603_01ZMqX1oSzaCMeta?.name ?? "story-ep06-ep06_020603_01___nl",
    path: ep06_020603_01ZMqX1oSzaCMeta?.path ?? "/nl/story/ep06/ep06_020603_01",
    meta: ep06_020603_01ZMqX1oSzaCMeta || {},
    alias: ep06_020603_01ZMqX1oSzaCMeta?.alias || [],
    redirect: ep06_020603_01ZMqX1oSzaCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020603_01.vue").then(m => m.default || m)
  },
  {
    name: ep06_020603_01ZMqX1oSzaCMeta?.name ?? "story-ep06-ep06_020603_01___es",
    path: ep06_020603_01ZMqX1oSzaCMeta?.path ?? "/es/story/ep06/ep06_020603_01",
    meta: ep06_020603_01ZMqX1oSzaCMeta || {},
    alias: ep06_020603_01ZMqX1oSzaCMeta?.alias || [],
    redirect: ep06_020603_01ZMqX1oSzaCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/ep06_020603_01.vue").then(m => m.default || m)
  },
  {
    name: indexAw5C94z6GDMeta?.name ?? "story-ep06___en-US",
    path: indexAw5C94z6GDMeta?.path ?? "/story/ep06",
    meta: indexAw5C94z6GDMeta || {},
    alias: indexAw5C94z6GDMeta?.alias || [],
    redirect: indexAw5C94z6GDMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/index.vue").then(m => m.default || m)
  },
  {
    name: indexAw5C94z6GDMeta?.name ?? "story-ep06___de",
    path: indexAw5C94z6GDMeta?.path ?? "/de/story/ep06",
    meta: indexAw5C94z6GDMeta || {},
    alias: indexAw5C94z6GDMeta?.alias || [],
    redirect: indexAw5C94z6GDMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/index.vue").then(m => m.default || m)
  },
  {
    name: indexAw5C94z6GDMeta?.name ?? "story-ep06___fr",
    path: indexAw5C94z6GDMeta?.path ?? "/fr/story/ep06",
    meta: indexAw5C94z6GDMeta || {},
    alias: indexAw5C94z6GDMeta?.alias || [],
    redirect: indexAw5C94z6GDMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/index.vue").then(m => m.default || m)
  },
  {
    name: indexAw5C94z6GDMeta?.name ?? "story-ep06___no",
    path: indexAw5C94z6GDMeta?.path ?? "/no/story/ep06",
    meta: indexAw5C94z6GDMeta || {},
    alias: indexAw5C94z6GDMeta?.alias || [],
    redirect: indexAw5C94z6GDMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/index.vue").then(m => m.default || m)
  },
  {
    name: indexAw5C94z6GDMeta?.name ?? "story-ep06___nl",
    path: indexAw5C94z6GDMeta?.path ?? "/nl/story/ep06",
    meta: indexAw5C94z6GDMeta || {},
    alias: indexAw5C94z6GDMeta?.alias || [],
    redirect: indexAw5C94z6GDMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/index.vue").then(m => m.default || m)
  },
  {
    name: indexAw5C94z6GDMeta?.name ?? "story-ep06___es",
    path: indexAw5C94z6GDMeta?.path ?? "/es/story/ep06",
    meta: indexAw5C94z6GDMeta || {},
    alias: indexAw5C94z6GDMeta?.alias || [],
    redirect: indexAw5C94z6GDMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep06/index.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_01gMzkm2ABgCMeta?.name ?? "story-ep07-ep07_020702_01___en-US",
    path: ep07_020702_01gMzkm2ABgCMeta?.path ?? "/story/ep07/ep07_020702_01",
    meta: ep07_020702_01gMzkm2ABgCMeta || {},
    alias: ep07_020702_01gMzkm2ABgCMeta?.alias || [],
    redirect: ep07_020702_01gMzkm2ABgCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_01.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_01gMzkm2ABgCMeta?.name ?? "story-ep07-ep07_020702_01___de",
    path: ep07_020702_01gMzkm2ABgCMeta?.path ?? "/de/story/ep07/ep07_020702_01",
    meta: ep07_020702_01gMzkm2ABgCMeta || {},
    alias: ep07_020702_01gMzkm2ABgCMeta?.alias || [],
    redirect: ep07_020702_01gMzkm2ABgCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_01.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_01gMzkm2ABgCMeta?.name ?? "story-ep07-ep07_020702_01___fr",
    path: ep07_020702_01gMzkm2ABgCMeta?.path ?? "/fr/story/ep07/ep07_020702_01",
    meta: ep07_020702_01gMzkm2ABgCMeta || {},
    alias: ep07_020702_01gMzkm2ABgCMeta?.alias || [],
    redirect: ep07_020702_01gMzkm2ABgCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_01.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_01gMzkm2ABgCMeta?.name ?? "story-ep07-ep07_020702_01___no",
    path: ep07_020702_01gMzkm2ABgCMeta?.path ?? "/no/story/ep07/ep07_020702_01",
    meta: ep07_020702_01gMzkm2ABgCMeta || {},
    alias: ep07_020702_01gMzkm2ABgCMeta?.alias || [],
    redirect: ep07_020702_01gMzkm2ABgCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_01.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_01gMzkm2ABgCMeta?.name ?? "story-ep07-ep07_020702_01___nl",
    path: ep07_020702_01gMzkm2ABgCMeta?.path ?? "/nl/story/ep07/ep07_020702_01",
    meta: ep07_020702_01gMzkm2ABgCMeta || {},
    alias: ep07_020702_01gMzkm2ABgCMeta?.alias || [],
    redirect: ep07_020702_01gMzkm2ABgCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_01.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_01gMzkm2ABgCMeta?.name ?? "story-ep07-ep07_020702_01___es",
    path: ep07_020702_01gMzkm2ABgCMeta?.path ?? "/es/story/ep07/ep07_020702_01",
    meta: ep07_020702_01gMzkm2ABgCMeta || {},
    alias: ep07_020702_01gMzkm2ABgCMeta?.alias || [],
    redirect: ep07_020702_01gMzkm2ABgCMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_01.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_02daoTPNWWYkMeta?.name ?? "story-ep07-ep07_020702_02___en-US",
    path: ep07_020702_02daoTPNWWYkMeta?.path ?? "/story/ep07/ep07_020702_02",
    meta: ep07_020702_02daoTPNWWYkMeta || {},
    alias: ep07_020702_02daoTPNWWYkMeta?.alias || [],
    redirect: ep07_020702_02daoTPNWWYkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_02.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_02daoTPNWWYkMeta?.name ?? "story-ep07-ep07_020702_02___de",
    path: ep07_020702_02daoTPNWWYkMeta?.path ?? "/de/story/ep07/ep07_020702_02",
    meta: ep07_020702_02daoTPNWWYkMeta || {},
    alias: ep07_020702_02daoTPNWWYkMeta?.alias || [],
    redirect: ep07_020702_02daoTPNWWYkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_02.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_02daoTPNWWYkMeta?.name ?? "story-ep07-ep07_020702_02___fr",
    path: ep07_020702_02daoTPNWWYkMeta?.path ?? "/fr/story/ep07/ep07_020702_02",
    meta: ep07_020702_02daoTPNWWYkMeta || {},
    alias: ep07_020702_02daoTPNWWYkMeta?.alias || [],
    redirect: ep07_020702_02daoTPNWWYkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_02.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_02daoTPNWWYkMeta?.name ?? "story-ep07-ep07_020702_02___no",
    path: ep07_020702_02daoTPNWWYkMeta?.path ?? "/no/story/ep07/ep07_020702_02",
    meta: ep07_020702_02daoTPNWWYkMeta || {},
    alias: ep07_020702_02daoTPNWWYkMeta?.alias || [],
    redirect: ep07_020702_02daoTPNWWYkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_02.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_02daoTPNWWYkMeta?.name ?? "story-ep07-ep07_020702_02___nl",
    path: ep07_020702_02daoTPNWWYkMeta?.path ?? "/nl/story/ep07/ep07_020702_02",
    meta: ep07_020702_02daoTPNWWYkMeta || {},
    alias: ep07_020702_02daoTPNWWYkMeta?.alias || [],
    redirect: ep07_020702_02daoTPNWWYkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_02.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_02daoTPNWWYkMeta?.name ?? "story-ep07-ep07_020702_02___es",
    path: ep07_020702_02daoTPNWWYkMeta?.path ?? "/es/story/ep07/ep07_020702_02",
    meta: ep07_020702_02daoTPNWWYkMeta || {},
    alias: ep07_020702_02daoTPNWWYkMeta?.alias || [],
    redirect: ep07_020702_02daoTPNWWYkMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_02.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_03TVkj5r5qUOMeta?.name ?? "story-ep07-ep07_020702_03___en-US",
    path: ep07_020702_03TVkj5r5qUOMeta?.path ?? "/story/ep07/ep07_020702_03",
    meta: ep07_020702_03TVkj5r5qUOMeta || {},
    alias: ep07_020702_03TVkj5r5qUOMeta?.alias || [],
    redirect: ep07_020702_03TVkj5r5qUOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_03.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_03TVkj5r5qUOMeta?.name ?? "story-ep07-ep07_020702_03___de",
    path: ep07_020702_03TVkj5r5qUOMeta?.path ?? "/de/story/ep07/ep07_020702_03",
    meta: ep07_020702_03TVkj5r5qUOMeta || {},
    alias: ep07_020702_03TVkj5r5qUOMeta?.alias || [],
    redirect: ep07_020702_03TVkj5r5qUOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_03.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_03TVkj5r5qUOMeta?.name ?? "story-ep07-ep07_020702_03___fr",
    path: ep07_020702_03TVkj5r5qUOMeta?.path ?? "/fr/story/ep07/ep07_020702_03",
    meta: ep07_020702_03TVkj5r5qUOMeta || {},
    alias: ep07_020702_03TVkj5r5qUOMeta?.alias || [],
    redirect: ep07_020702_03TVkj5r5qUOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_03.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_03TVkj5r5qUOMeta?.name ?? "story-ep07-ep07_020702_03___no",
    path: ep07_020702_03TVkj5r5qUOMeta?.path ?? "/no/story/ep07/ep07_020702_03",
    meta: ep07_020702_03TVkj5r5qUOMeta || {},
    alias: ep07_020702_03TVkj5r5qUOMeta?.alias || [],
    redirect: ep07_020702_03TVkj5r5qUOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_03.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_03TVkj5r5qUOMeta?.name ?? "story-ep07-ep07_020702_03___nl",
    path: ep07_020702_03TVkj5r5qUOMeta?.path ?? "/nl/story/ep07/ep07_020702_03",
    meta: ep07_020702_03TVkj5r5qUOMeta || {},
    alias: ep07_020702_03TVkj5r5qUOMeta?.alias || [],
    redirect: ep07_020702_03TVkj5r5qUOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_03.vue").then(m => m.default || m)
  },
  {
    name: ep07_020702_03TVkj5r5qUOMeta?.name ?? "story-ep07-ep07_020702_03___es",
    path: ep07_020702_03TVkj5r5qUOMeta?.path ?? "/es/story/ep07/ep07_020702_03",
    meta: ep07_020702_03TVkj5r5qUOMeta || {},
    alias: ep07_020702_03TVkj5r5qUOMeta?.alias || [],
    redirect: ep07_020702_03TVkj5r5qUOMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/ep07_020702_03.vue").then(m => m.default || m)
  },
  {
    name: indexdBlOHZvZxbMeta?.name ?? "story-ep07___en-US",
    path: indexdBlOHZvZxbMeta?.path ?? "/story/ep07",
    meta: indexdBlOHZvZxbMeta || {},
    alias: indexdBlOHZvZxbMeta?.alias || [],
    redirect: indexdBlOHZvZxbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/index.vue").then(m => m.default || m)
  },
  {
    name: indexdBlOHZvZxbMeta?.name ?? "story-ep07___de",
    path: indexdBlOHZvZxbMeta?.path ?? "/de/story/ep07",
    meta: indexdBlOHZvZxbMeta || {},
    alias: indexdBlOHZvZxbMeta?.alias || [],
    redirect: indexdBlOHZvZxbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/index.vue").then(m => m.default || m)
  },
  {
    name: indexdBlOHZvZxbMeta?.name ?? "story-ep07___fr",
    path: indexdBlOHZvZxbMeta?.path ?? "/fr/story/ep07",
    meta: indexdBlOHZvZxbMeta || {},
    alias: indexdBlOHZvZxbMeta?.alias || [],
    redirect: indexdBlOHZvZxbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/index.vue").then(m => m.default || m)
  },
  {
    name: indexdBlOHZvZxbMeta?.name ?? "story-ep07___no",
    path: indexdBlOHZvZxbMeta?.path ?? "/no/story/ep07",
    meta: indexdBlOHZvZxbMeta || {},
    alias: indexdBlOHZvZxbMeta?.alias || [],
    redirect: indexdBlOHZvZxbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/index.vue").then(m => m.default || m)
  },
  {
    name: indexdBlOHZvZxbMeta?.name ?? "story-ep07___nl",
    path: indexdBlOHZvZxbMeta?.path ?? "/nl/story/ep07",
    meta: indexdBlOHZvZxbMeta || {},
    alias: indexdBlOHZvZxbMeta?.alias || [],
    redirect: indexdBlOHZvZxbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/index.vue").then(m => m.default || m)
  },
  {
    name: indexdBlOHZvZxbMeta?.name ?? "story-ep07___es",
    path: indexdBlOHZvZxbMeta?.path ?? "/es/story/ep07",
    meta: indexdBlOHZvZxbMeta || {},
    alias: indexdBlOHZvZxbMeta?.alias || [],
    redirect: indexdBlOHZvZxbMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep07/index.vue").then(m => m.default || m)
  },
  {
    name: ep08_02080131BxmzsPqiMeta?.name ?? "story-ep08-ep08_020801___en-US",
    path: ep08_02080131BxmzsPqiMeta?.path ?? "/story/ep08/ep08_020801",
    meta: ep08_02080131BxmzsPqiMeta || {},
    alias: ep08_02080131BxmzsPqiMeta?.alias || [],
    redirect: ep08_02080131BxmzsPqiMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020801.vue").then(m => m.default || m)
  },
  {
    name: ep08_02080131BxmzsPqiMeta?.name ?? "story-ep08-ep08_020801___de",
    path: ep08_02080131BxmzsPqiMeta?.path ?? "/de/story/ep08/ep08_020801",
    meta: ep08_02080131BxmzsPqiMeta || {},
    alias: ep08_02080131BxmzsPqiMeta?.alias || [],
    redirect: ep08_02080131BxmzsPqiMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020801.vue").then(m => m.default || m)
  },
  {
    name: ep08_02080131BxmzsPqiMeta?.name ?? "story-ep08-ep08_020801___fr",
    path: ep08_02080131BxmzsPqiMeta?.path ?? "/fr/story/ep08/ep08_020801",
    meta: ep08_02080131BxmzsPqiMeta || {},
    alias: ep08_02080131BxmzsPqiMeta?.alias || [],
    redirect: ep08_02080131BxmzsPqiMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020801.vue").then(m => m.default || m)
  },
  {
    name: ep08_02080131BxmzsPqiMeta?.name ?? "story-ep08-ep08_020801___no",
    path: ep08_02080131BxmzsPqiMeta?.path ?? "/no/story/ep08/ep08_020801",
    meta: ep08_02080131BxmzsPqiMeta || {},
    alias: ep08_02080131BxmzsPqiMeta?.alias || [],
    redirect: ep08_02080131BxmzsPqiMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020801.vue").then(m => m.default || m)
  },
  {
    name: ep08_02080131BxmzsPqiMeta?.name ?? "story-ep08-ep08_020801___nl",
    path: ep08_02080131BxmzsPqiMeta?.path ?? "/nl/story/ep08/ep08_020801",
    meta: ep08_02080131BxmzsPqiMeta || {},
    alias: ep08_02080131BxmzsPqiMeta?.alias || [],
    redirect: ep08_02080131BxmzsPqiMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020801.vue").then(m => m.default || m)
  },
  {
    name: ep08_02080131BxmzsPqiMeta?.name ?? "story-ep08-ep08_020801___es",
    path: ep08_02080131BxmzsPqiMeta?.path ?? "/es/story/ep08/ep08_020801",
    meta: ep08_02080131BxmzsPqiMeta || {},
    alias: ep08_02080131BxmzsPqiMeta?.alias || [],
    redirect: ep08_02080131BxmzsPqiMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020801.vue").then(m => m.default || m)
  },
  {
    name: ep08_020802zM4eLdG1ngMeta?.name ?? "story-ep08-ep08_020802___en-US",
    path: ep08_020802zM4eLdG1ngMeta?.path ?? "/story/ep08/ep08_020802",
    meta: ep08_020802zM4eLdG1ngMeta || {},
    alias: ep08_020802zM4eLdG1ngMeta?.alias || [],
    redirect: ep08_020802zM4eLdG1ngMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020802.vue").then(m => m.default || m)
  },
  {
    name: ep08_020802zM4eLdG1ngMeta?.name ?? "story-ep08-ep08_020802___de",
    path: ep08_020802zM4eLdG1ngMeta?.path ?? "/de/story/ep08/ep08_020802",
    meta: ep08_020802zM4eLdG1ngMeta || {},
    alias: ep08_020802zM4eLdG1ngMeta?.alias || [],
    redirect: ep08_020802zM4eLdG1ngMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020802.vue").then(m => m.default || m)
  },
  {
    name: ep08_020802zM4eLdG1ngMeta?.name ?? "story-ep08-ep08_020802___fr",
    path: ep08_020802zM4eLdG1ngMeta?.path ?? "/fr/story/ep08/ep08_020802",
    meta: ep08_020802zM4eLdG1ngMeta || {},
    alias: ep08_020802zM4eLdG1ngMeta?.alias || [],
    redirect: ep08_020802zM4eLdG1ngMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020802.vue").then(m => m.default || m)
  },
  {
    name: ep08_020802zM4eLdG1ngMeta?.name ?? "story-ep08-ep08_020802___no",
    path: ep08_020802zM4eLdG1ngMeta?.path ?? "/no/story/ep08/ep08_020802",
    meta: ep08_020802zM4eLdG1ngMeta || {},
    alias: ep08_020802zM4eLdG1ngMeta?.alias || [],
    redirect: ep08_020802zM4eLdG1ngMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020802.vue").then(m => m.default || m)
  },
  {
    name: ep08_020802zM4eLdG1ngMeta?.name ?? "story-ep08-ep08_020802___nl",
    path: ep08_020802zM4eLdG1ngMeta?.path ?? "/nl/story/ep08/ep08_020802",
    meta: ep08_020802zM4eLdG1ngMeta || {},
    alias: ep08_020802zM4eLdG1ngMeta?.alias || [],
    redirect: ep08_020802zM4eLdG1ngMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020802.vue").then(m => m.default || m)
  },
  {
    name: ep08_020802zM4eLdG1ngMeta?.name ?? "story-ep08-ep08_020802___es",
    path: ep08_020802zM4eLdG1ngMeta?.path ?? "/es/story/ep08/ep08_020802",
    meta: ep08_020802zM4eLdG1ngMeta || {},
    alias: ep08_020802zM4eLdG1ngMeta?.alias || [],
    redirect: ep08_020802zM4eLdG1ngMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020802.vue").then(m => m.default || m)
  },
  {
    name: ep08_020803SNvsZisusBMeta?.name ?? "story-ep08-ep08_020803___en-US",
    path: ep08_020803SNvsZisusBMeta?.path ?? "/story/ep08/ep08_020803",
    meta: ep08_020803SNvsZisusBMeta || {},
    alias: ep08_020803SNvsZisusBMeta?.alias || [],
    redirect: ep08_020803SNvsZisusBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020803.vue").then(m => m.default || m)
  },
  {
    name: ep08_020803SNvsZisusBMeta?.name ?? "story-ep08-ep08_020803___de",
    path: ep08_020803SNvsZisusBMeta?.path ?? "/de/story/ep08/ep08_020803",
    meta: ep08_020803SNvsZisusBMeta || {},
    alias: ep08_020803SNvsZisusBMeta?.alias || [],
    redirect: ep08_020803SNvsZisusBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020803.vue").then(m => m.default || m)
  },
  {
    name: ep08_020803SNvsZisusBMeta?.name ?? "story-ep08-ep08_020803___fr",
    path: ep08_020803SNvsZisusBMeta?.path ?? "/fr/story/ep08/ep08_020803",
    meta: ep08_020803SNvsZisusBMeta || {},
    alias: ep08_020803SNvsZisusBMeta?.alias || [],
    redirect: ep08_020803SNvsZisusBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020803.vue").then(m => m.default || m)
  },
  {
    name: ep08_020803SNvsZisusBMeta?.name ?? "story-ep08-ep08_020803___no",
    path: ep08_020803SNvsZisusBMeta?.path ?? "/no/story/ep08/ep08_020803",
    meta: ep08_020803SNvsZisusBMeta || {},
    alias: ep08_020803SNvsZisusBMeta?.alias || [],
    redirect: ep08_020803SNvsZisusBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020803.vue").then(m => m.default || m)
  },
  {
    name: ep08_020803SNvsZisusBMeta?.name ?? "story-ep08-ep08_020803___nl",
    path: ep08_020803SNvsZisusBMeta?.path ?? "/nl/story/ep08/ep08_020803",
    meta: ep08_020803SNvsZisusBMeta || {},
    alias: ep08_020803SNvsZisusBMeta?.alias || [],
    redirect: ep08_020803SNvsZisusBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020803.vue").then(m => m.default || m)
  },
  {
    name: ep08_020803SNvsZisusBMeta?.name ?? "story-ep08-ep08_020803___es",
    path: ep08_020803SNvsZisusBMeta?.path ?? "/es/story/ep08/ep08_020803",
    meta: ep08_020803SNvsZisusBMeta || {},
    alias: ep08_020803SNvsZisusBMeta?.alias || [],
    redirect: ep08_020803SNvsZisusBMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/ep08_020803.vue").then(m => m.default || m)
  },
  {
    name: indexRqAU4C7ynrMeta?.name ?? "story-ep08___en-US",
    path: indexRqAU4C7ynrMeta?.path ?? "/story/ep08",
    meta: indexRqAU4C7ynrMeta || {},
    alias: indexRqAU4C7ynrMeta?.alias || [],
    redirect: indexRqAU4C7ynrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqAU4C7ynrMeta?.name ?? "story-ep08___de",
    path: indexRqAU4C7ynrMeta?.path ?? "/de/story/ep08",
    meta: indexRqAU4C7ynrMeta || {},
    alias: indexRqAU4C7ynrMeta?.alias || [],
    redirect: indexRqAU4C7ynrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqAU4C7ynrMeta?.name ?? "story-ep08___fr",
    path: indexRqAU4C7ynrMeta?.path ?? "/fr/story/ep08",
    meta: indexRqAU4C7ynrMeta || {},
    alias: indexRqAU4C7ynrMeta?.alias || [],
    redirect: indexRqAU4C7ynrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqAU4C7ynrMeta?.name ?? "story-ep08___no",
    path: indexRqAU4C7ynrMeta?.path ?? "/no/story/ep08",
    meta: indexRqAU4C7ynrMeta || {},
    alias: indexRqAU4C7ynrMeta?.alias || [],
    redirect: indexRqAU4C7ynrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqAU4C7ynrMeta?.name ?? "story-ep08___nl",
    path: indexRqAU4C7ynrMeta?.path ?? "/nl/story/ep08",
    meta: indexRqAU4C7ynrMeta || {},
    alias: indexRqAU4C7ynrMeta?.alias || [],
    redirect: indexRqAU4C7ynrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqAU4C7ynrMeta?.name ?? "story-ep08___es",
    path: indexRqAU4C7ynrMeta?.path ?? "/es/story/ep08",
    meta: indexRqAU4C7ynrMeta || {},
    alias: indexRqAU4C7ynrMeta?.alias || [],
    redirect: indexRqAU4C7ynrMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/ep08/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ9qoxqqjixMeta?.name ?? "story___en-US",
    path: indexZ9qoxqqjixMeta?.path ?? "/story",
    meta: indexZ9qoxqqjixMeta || {},
    alias: indexZ9qoxqqjixMeta?.alias || [],
    redirect: indexZ9qoxqqjixMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ9qoxqqjixMeta?.name ?? "story___de",
    path: indexZ9qoxqqjixMeta?.path ?? "/de/story",
    meta: indexZ9qoxqqjixMeta || {},
    alias: indexZ9qoxqqjixMeta?.alias || [],
    redirect: indexZ9qoxqqjixMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ9qoxqqjixMeta?.name ?? "story___fr",
    path: indexZ9qoxqqjixMeta?.path ?? "/fr/story",
    meta: indexZ9qoxqqjixMeta || {},
    alias: indexZ9qoxqqjixMeta?.alias || [],
    redirect: indexZ9qoxqqjixMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ9qoxqqjixMeta?.name ?? "story___no",
    path: indexZ9qoxqqjixMeta?.path ?? "/no/story",
    meta: indexZ9qoxqqjixMeta || {},
    alias: indexZ9qoxqqjixMeta?.alias || [],
    redirect: indexZ9qoxqqjixMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ9qoxqqjixMeta?.name ?? "story___nl",
    path: indexZ9qoxqqjixMeta?.path ?? "/nl/story",
    meta: indexZ9qoxqqjixMeta || {},
    alias: indexZ9qoxqqjixMeta?.alias || [],
    redirect: indexZ9qoxqqjixMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ9qoxqqjixMeta?.name ?? "story___es",
    path: indexZ9qoxqqjixMeta?.path ?? "/es/story",
    meta: indexZ9qoxqqjixMeta || {},
    alias: indexZ9qoxqqjixMeta?.alias || [],
    redirect: indexZ9qoxqqjixMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/index.vue").then(m => m.default || m)
  },
  {
    name: indexol3jX3QVz9Meta?.name ?? "story-intro___en-US",
    path: indexol3jX3QVz9Meta?.path ?? "/story/intro",
    meta: indexol3jX3QVz9Meta || {},
    alias: indexol3jX3QVz9Meta?.alias || [],
    redirect: indexol3jX3QVz9Meta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/intro/index.vue").then(m => m.default || m)
  },
  {
    name: indexol3jX3QVz9Meta?.name ?? "story-intro___de",
    path: indexol3jX3QVz9Meta?.path ?? "/de/story/intro",
    meta: indexol3jX3QVz9Meta || {},
    alias: indexol3jX3QVz9Meta?.alias || [],
    redirect: indexol3jX3QVz9Meta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/intro/index.vue").then(m => m.default || m)
  },
  {
    name: indexol3jX3QVz9Meta?.name ?? "story-intro___fr",
    path: indexol3jX3QVz9Meta?.path ?? "/fr/story/intro",
    meta: indexol3jX3QVz9Meta || {},
    alias: indexol3jX3QVz9Meta?.alias || [],
    redirect: indexol3jX3QVz9Meta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/intro/index.vue").then(m => m.default || m)
  },
  {
    name: indexol3jX3QVz9Meta?.name ?? "story-intro___no",
    path: indexol3jX3QVz9Meta?.path ?? "/no/story/intro",
    meta: indexol3jX3QVz9Meta || {},
    alias: indexol3jX3QVz9Meta?.alias || [],
    redirect: indexol3jX3QVz9Meta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/intro/index.vue").then(m => m.default || m)
  },
  {
    name: indexol3jX3QVz9Meta?.name ?? "story-intro___nl",
    path: indexol3jX3QVz9Meta?.path ?? "/nl/story/intro",
    meta: indexol3jX3QVz9Meta || {},
    alias: indexol3jX3QVz9Meta?.alias || [],
    redirect: indexol3jX3QVz9Meta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/intro/index.vue").then(m => m.default || m)
  },
  {
    name: indexol3jX3QVz9Meta?.name ?? "story-intro___es",
    path: indexol3jX3QVz9Meta?.path ?? "/es/story/intro",
    meta: indexol3jX3QVz9Meta || {},
    alias: indexol3jX3QVz9Meta?.alias || [],
    redirect: indexol3jX3QVz9Meta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/intro/index.vue").then(m => m.default || m)
  },
  {
    name: index1LYDvqicdAMeta?.name ?? "story-outro___en-US",
    path: index1LYDvqicdAMeta?.path ?? "/story/outro",
    meta: index1LYDvqicdAMeta || {},
    alias: index1LYDvqicdAMeta?.alias || [],
    redirect: index1LYDvqicdAMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/outro/index.vue").then(m => m.default || m)
  },
  {
    name: index1LYDvqicdAMeta?.name ?? "story-outro___de",
    path: index1LYDvqicdAMeta?.path ?? "/de/story/outro",
    meta: index1LYDvqicdAMeta || {},
    alias: index1LYDvqicdAMeta?.alias || [],
    redirect: index1LYDvqicdAMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/outro/index.vue").then(m => m.default || m)
  },
  {
    name: index1LYDvqicdAMeta?.name ?? "story-outro___fr",
    path: index1LYDvqicdAMeta?.path ?? "/fr/story/outro",
    meta: index1LYDvqicdAMeta || {},
    alias: index1LYDvqicdAMeta?.alias || [],
    redirect: index1LYDvqicdAMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/outro/index.vue").then(m => m.default || m)
  },
  {
    name: index1LYDvqicdAMeta?.name ?? "story-outro___no",
    path: index1LYDvqicdAMeta?.path ?? "/no/story/outro",
    meta: index1LYDvqicdAMeta || {},
    alias: index1LYDvqicdAMeta?.alias || [],
    redirect: index1LYDvqicdAMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/outro/index.vue").then(m => m.default || m)
  },
  {
    name: index1LYDvqicdAMeta?.name ?? "story-outro___nl",
    path: index1LYDvqicdAMeta?.path ?? "/nl/story/outro",
    meta: index1LYDvqicdAMeta || {},
    alias: index1LYDvqicdAMeta?.alias || [],
    redirect: index1LYDvqicdAMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/outro/index.vue").then(m => m.default || m)
  },
  {
    name: index1LYDvqicdAMeta?.name ?? "story-outro___es",
    path: index1LYDvqicdAMeta?.path ?? "/es/story/outro",
    meta: index1LYDvqicdAMeta || {},
    alias: index1LYDvqicdAMeta?.alias || [],
    redirect: index1LYDvqicdAMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/story/outro/index.vue").then(m => m.default || m)
  },
  {
    name: indexjtPc4c3QOSMeta?.name ?? "webtoon-01___en-US",
    path: indexjtPc4c3QOSMeta?.path ?? "/webtoon/01",
    meta: indexjtPc4c3QOSMeta || {},
    alias: indexjtPc4c3QOSMeta?.alias || [],
    redirect: indexjtPc4c3QOSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/01/index.vue").then(m => m.default || m)
  },
  {
    name: indexjtPc4c3QOSMeta?.name ?? "webtoon-01___de",
    path: indexjtPc4c3QOSMeta?.path ?? "/de/webtoon/01",
    meta: indexjtPc4c3QOSMeta || {},
    alias: indexjtPc4c3QOSMeta?.alias || [],
    redirect: indexjtPc4c3QOSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/01/index.vue").then(m => m.default || m)
  },
  {
    name: indexjtPc4c3QOSMeta?.name ?? "webtoon-01___fr",
    path: indexjtPc4c3QOSMeta?.path ?? "/fr/webtoon/01",
    meta: indexjtPc4c3QOSMeta || {},
    alias: indexjtPc4c3QOSMeta?.alias || [],
    redirect: indexjtPc4c3QOSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/01/index.vue").then(m => m.default || m)
  },
  {
    name: indexjtPc4c3QOSMeta?.name ?? "webtoon-01___no",
    path: indexjtPc4c3QOSMeta?.path ?? "/no/webtoon/01",
    meta: indexjtPc4c3QOSMeta || {},
    alias: indexjtPc4c3QOSMeta?.alias || [],
    redirect: indexjtPc4c3QOSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/01/index.vue").then(m => m.default || m)
  },
  {
    name: indexjtPc4c3QOSMeta?.name ?? "webtoon-01___nl",
    path: indexjtPc4c3QOSMeta?.path ?? "/nl/webtoon/01",
    meta: indexjtPc4c3QOSMeta || {},
    alias: indexjtPc4c3QOSMeta?.alias || [],
    redirect: indexjtPc4c3QOSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/01/index.vue").then(m => m.default || m)
  },
  {
    name: indexjtPc4c3QOSMeta?.name ?? "webtoon-01___es",
    path: indexjtPc4c3QOSMeta?.path ?? "/es/webtoon/01",
    meta: indexjtPc4c3QOSMeta || {},
    alias: indexjtPc4c3QOSMeta?.alias || [],
    redirect: indexjtPc4c3QOSMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/01/index.vue").then(m => m.default || m)
  },
  {
    name: index96kEVgf3JqMeta?.name ?? "webtoon___en-US",
    path: index96kEVgf3JqMeta?.path ?? "/webtoon",
    meta: index96kEVgf3JqMeta || {},
    alias: index96kEVgf3JqMeta?.alias || [],
    redirect: index96kEVgf3JqMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/index.vue").then(m => m.default || m)
  },
  {
    name: index96kEVgf3JqMeta?.name ?? "webtoon___de",
    path: index96kEVgf3JqMeta?.path ?? "/de/webtoon",
    meta: index96kEVgf3JqMeta || {},
    alias: index96kEVgf3JqMeta?.alias || [],
    redirect: index96kEVgf3JqMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/index.vue").then(m => m.default || m)
  },
  {
    name: index96kEVgf3JqMeta?.name ?? "webtoon___fr",
    path: index96kEVgf3JqMeta?.path ?? "/fr/webtoon",
    meta: index96kEVgf3JqMeta || {},
    alias: index96kEVgf3JqMeta?.alias || [],
    redirect: index96kEVgf3JqMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/index.vue").then(m => m.default || m)
  },
  {
    name: index96kEVgf3JqMeta?.name ?? "webtoon___no",
    path: index96kEVgf3JqMeta?.path ?? "/no/webtoon",
    meta: index96kEVgf3JqMeta || {},
    alias: index96kEVgf3JqMeta?.alias || [],
    redirect: index96kEVgf3JqMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/index.vue").then(m => m.default || m)
  },
  {
    name: index96kEVgf3JqMeta?.name ?? "webtoon___nl",
    path: index96kEVgf3JqMeta?.path ?? "/nl/webtoon",
    meta: index96kEVgf3JqMeta || {},
    alias: index96kEVgf3JqMeta?.alias || [],
    redirect: index96kEVgf3JqMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/index.vue").then(m => m.default || m)
  },
  {
    name: index96kEVgf3JqMeta?.name ?? "webtoon___es",
    path: index96kEVgf3JqMeta?.path ?? "/es/webtoon",
    meta: index96kEVgf3JqMeta || {},
    alias: index96kEVgf3JqMeta?.alias || [],
    redirect: index96kEVgf3JqMeta?.redirect,
    component: () => import("/home/www/experienceioniq6/actions-runner/_work/hyundai_ioniq6_vue3/hyundai_ioniq6_vue3/pages/webtoon/index.vue").then(m => m.default || m)
  }
]