import { useCountryStore } from '@/stores/country'

export default defineNuxtPlugin(async (nuxtApp) => {
  const store = useCountryStore(nuxtApp.$pinia)
  // const i18n = nuxtApp.$i18n;

  nuxtApp.$router.beforeEach(async (to, from, next) => {
    const currentPath = to.path

    console.log('Starting i18n Auto setup...')

    const countryMatchList = [
      { iso_code: 'DE', i18n_code: 'de' },
      { iso_code: 'FR', i18n_code: 'fr' },
      { iso_code: 'NO', i18n_code: 'no' },
      { iso_code: 'NL', i18n_code: 'nl' },
      { iso_code: 'ES', i18n_code: 'es' },
      { iso_code: 'US', i18n_code: 'en-US' },
    ]

    // 배포 전 api 엑세스 카운트 확보를 위해 운영 키 미반영 처리
    // const apiKey = (process.env.IP_STACK_API_ACCESS_KEY !== '' || process.env.IP_STACK_API_ACCESS_KEY !== undefined) ? process.env.IP_STACK_API_ACCESS_KEY : ''//

    function findObjectByKey(array, key, searchObject) {
      return array.find((obj) => obj[key] === searchObject)
    }

    const getIsoCode = (countryCode) => {
      // iso_code를 내부 데이터와 비교해서 설정값에 맞는 i18n_code를 출력
      const matchedI18nCountry = findObjectByKey(
        countryMatchList,
        'iso_code',
        countryCode
      )

      console.log(matchedI18nCountry)
      // en-US는 기본 값
      const i18n_code =
        matchedI18nCountry !== null || matchedI18nCountry !== undefined
          ? matchedI18nCountry?.i18n_code
          : 'en-US'

      return i18n_code
    }

    if (process.server) {
      let isoCode
      let isAllowPath = false

      // check-health route 예외 처리
      if (process.env.NOT_ALLOW_I18N_URI !== '') {
        isAllowPath =
          currentPath !== process.env.NOT_ALLOW_I18N_URI ? true : false
      }

      isAllowPath = false

      console.log('path >>>>>>>>>>>>>>>>>>>>>>')
      console.log(currentPath)

      try {
        if (isAllowPath) {
          // 1. Ip 주소 확인
          const url = `${apiUrl}${apiKey}`
          const defaultOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }

          console.log('Start get Ip location Information...')
          await fetch(url, defaultOptions)
            .then((response) => response.json())
            .then((data) => {
              console.log('Start get Ip location Information... DONE')
              console.log('===== Client IP Information =====')

              if (data) {
                console.log(`Ip address : ${data.ip}`)
                const { country_code, continent_name } = data
                //   "continent_name": "Europe", "Asia" ...
                console.log(`Client's country code is ${country_code}`)
                console.log(`Contient name is ${continent_name}`)

                if (
                  country_code !== undefined &&
                  continent_name !== undefined
                ) {
                  isoCode = getIsoCode(country_code)

                  console.log('===== store committed =====')

                  // api 중복 호출을 확인용으로 store에 저장
                  // store.commit('country/setContinentName', continent_name)
                  store.setContinentName(continent_name)
                  // store.commit('country/setCountry', country_code)
                  store.setCountry(country_code)
                  // store.commit('country/setIsoCode', isoCode)
                  store.setIsoCode(isoCode)
                  // i18n.setLocale(isoCode)
                }
              } else {
                if (data.error) {
                  console.log(`error : ${data.error.type}`)
                  console.log(data.error.info)
                }
              }
            })
        } else {
          console.log('WARNING : It is not allow to run this url')
        }
      } catch (error) {
        console.error(error)
        return false
      }
    }


    next()
  })
})
