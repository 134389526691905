export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.provide('addBodyClassByi18n', (newVal: string, oldVal: string) => {
        if (process.client) {
            if (document.body !== undefined) {
                if (oldVal !== '') {
                    document.body.classList.remove(oldVal);
                }
                document.body.classList.add(newVal);
            }
        }
    });
});