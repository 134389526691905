export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Experience features of our new IONIQ 6 with an immersive virtual simulation, a cartoon, and a retro-style racing game!"},{"hid":"keywords","name":"keywords","content":"EV, IONIQ, IONIQ6, IONIQ5, Shell, Recharge, VX, cartoon, game, simulation, animation"},{"name":"format-detection","content":"telephone=no"},{"hid":"og:site_name","property":"og:site_name","content":"Experience IONIQ 6"},{"hid":"og:title","property":"og:title","content":"Experience IONIQ 6"},{"hid":"og:description","property":"og:description","content":"Experience features of our new IONIQ 6 with an immersive virtual simulation, a cartoon, and a retro-style racing game!"},{"property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https://experienceioniq6.hyundai.com"},{"name":"apple-mobile-web-app-title","content":"Exp IONIQ 6"},{"name":"application-name","content":"Exp IONIQ 6"},{"name":"msapplication-TileColor","content":"#0a2960"},{"name":"msapplication-config","content":"/images/favicon/browserconfig.xml"},{"name":"theme-color","content":"#0a2960"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/images/favicon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/images/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/images/favicon/favicon-16x16.png"},{"rel":"manifest","href":"/images/favicon/site.webmanifest"},{"rel":"mask-icon","href":"/images/favicon/safari-pinned-tab.svg","color":"#0a2960"},{"rel":"shortcut icon","href":"/images/favicon/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"Experience IONIQ 6","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false